<template>
  <base-table :data="table" thead-classes="text-primary">
    <template slot="columns" slot-scope="{ columns }">
      <th>#</th>
      <th>Name</th>
      <!-- <th>Job Position</th> -->
      <th>savings</th>
     <th class="text-right">Milestone</th> 
      <th class="text-right">Likes</th> 
    </template>

    <template slot-scope="{ row, index }">
      <td class="text-center">
        <!-- <div class="photo"><img :src="row.img" alt="photo" /></div> -->
        {{index+1}}
      </td>
      <td>{{ row.name }}</td>
      <td>${{ row.saved }}</td>
      <td class="text-center"><base-progress :value="row.progress" /></td>
        <td class="text-right">
          {{row.like}}
         <el-tooltip
          content="Like"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
           @click.native="handleLike(row.key)"
            :type="row.liked? 'success' : 'info'"
            icon
            size="sm"
            class="btn-link"
          >
            
            <i class="tim-icons icon-heart-2"></i>
          </base-button>
        </el-tooltip>
      </td>
    </template>
  </base-table>
</template>
<script>
import { BaseTable, BaseProgress } from '@/components';
import firebase from '../../Firebase';
import moment from 'moment';

export default {
  components: {
    BaseTable,
    BaseProgress
  },
  data() {
    return {
      liked: [],
      table: [],
       uid:"",
    };
  },
  created(){

 const currentUser = firebase.auth().currentUser
  this.uid = currentUser.uid;

 firebase.database().ref('users')
    .orderByChild('saved').limitToLast(6).on('value', users =>{
this.table=[]
users.forEach(user=>{
  let like =parseInt(user.child("like").val())>0?user.child("like").val():0;
  let liked=this.liked.includes(user.ref.key)
this.table.push({
          key:user.ref.key,
          name:user.child("firstName").val()+" "+user.child("lastName").val(),
          uid:user.ref.key,
          saved: this.formatPrice(user.child("saved").val()),
          like: like,
          liked: liked,
          progress: (100*(user.child("saved").val()/user.child("goal").val())).toFixed(2),
          icon:user.child("icon").val(),
})
})
this.table.sort((a, b) => a.saved - b.saved );
this.table.reverse();

 
    })

this.table.forEach(async (post,index) => {
let wasliked= await firebase.database().ref('users')
.child(this.uid)
.child("liked").child(post.key).once("value");
 this.table[index].liked = wasliked.numChildren()>0?true:false
if(wasliked.numChildren()>0){
this.liked.push(post.key);
}

 });
    


  },
  methods:{
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    handleLike(index) {

if(this.liked.includes(index)){

var index1 = this.liked.indexOf(index);
this.liked.splice(index1, 1);

firebase.database().ref('users').child(index).update({
  like:firebase.database.ServerValue.increment(-1)

})
firebase.database().ref('users').child(this.uid).child("liked").child(index).remove();

}else{


this.liked.push(index);
firebase.database().ref('users').child(index).update({
  like:firebase.database.ServerValue.increment(1)

})

firebase.database().ref('users').child(this.uid).child("liked").child(index).set({
  like:index
})
}

    },
  }
};
</script>
<style></style>

<template>
    <card class="card-stats" :show-footer-line="true">
      <div class="row">
        <div class="col-5" v-if="$slots.icon || icon">
          <div class="info-icon text-center" :class="`icon-${type}`">
            <slot name="icon"> <i :class="icon"></i> </slot>
          </div>
          <!-- <button v-on:click="childMethod">CLICK</button> @click="info(0, device.device)"-->
          
        </div>
        
       
        <div class="col-7" v-if="$slots.content || title || subTitle">
          <div class="numbers">
            <slot>
              <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
              <h3 v-if="title" class="card-title">{{ total }}</h3>
            </slot>
          </div>
        </div>

        <div v-if="tipo==='device'" class="col-5">
            <base-button  @click="info(0,device)"  type="danger" round icon>
            <i class="tim-icons icon-button-power"> </i>
          </base-button>
          {{(phase1).toFixed(2)}}
        </div>
        <div v-if="num===2" class="col-5">
            
            <base-button  @click="info(1, device)"  type="danger" round icon>
            <i class="tim-icons icon-button-power"> </i>
          </base-button>
          {{(phase2).toFixed(2)}} 
        </div>
      </div>
    
       
       
      <div class="stats" slot="footer" v-if="footer">
    
    <div v-if="route&&tipo.includes('meter')">
  <router-link :to="route"><i class="tim-icons icon-link-72"></i>{{ footer }}</router-link>
    </div>
    <div v-else>
        {{ footer }}
    </div>
      </div>
  
        
    </card>
  </template>
  <script>
import { integer } from 'vee-validate/dist/rules';
import Card from './Card.vue';

import moment from 'moment';

import firebase from '../../Firebase';
  
  export default {
    name: 'meter-card',
    components: {
      Card
    },
    data(){
      return{
        phase1:0,
        phase2:0,
        phase3:0,
        total:0,
        timestamp:0,
        handle:{}
       

      }
    },
    props: {
      type: {
        type: String,
        default: 'primary'
      },
      icon: String,
      footer: String,
      title: String,
      subTitle: String,
      device_code:String,
      device:String,
      id:String,
      tipo:String,
      num:Number,
      route: String
    },
    mounted: function () {

//this.createPost()

this.handle = setInterval(() => {



  if (this.timestamp > (moment().subtract(2, 'minutes').unix() * 1000)) {
  //  if (true) {
   

    this.total=(this.phase1 + this.phase2+this.phase3).toFixed(2);

    
    if(this.tipo.includes('meter')){
    // this.statsCards[0].title = ((this.phase1 + this.phase2) * 0.17 / 1000).toFixed(2);


var inf = {meter:this.device_code,total:this.total,desc:this.footer,id:this.id}

this.$emit('cli', inf);

    }

//console.log("time:",(moment().subtract(2, 'minutes').unix() * 1000));

  } else {
    console.log("else", this.timestamp+" < "+(moment().subtract(2, 'minutes').unix() * 1000)+' type:'+this.tipo);
  //  this.statsCards[0].title = ((this.phase1 + this.phase2) * 0.17 / 1000).toFixed(2);
    this.total=0.00;

var inf = {meter:this.device_code,total:this.total,desc:this.footer,id:this.id}

this.$emit('cli', inf);
  }
}, 2000)

},

destroyed: function () {

clearInterval(this.handle);
},
    async created() {

        var meter = this.device_code;

        if(this.tipo==='device'){

            meter = this.device;

            this.icon = 'tim-icons icon-bulb-63';
            this.type='info';
        }
console.log("meter: "+meter)
        firebase.database()
      .ref("smeter").child(meter).child("instant")
      //.orderByChild("timestamp").startAt(moment()
      //.subtract(2,'minutes').unix()*1000)
      //.ref('manual').child("test")
      .on("value", notes => {
       
        // this.statsCards[1].title = notes.child("0_voltage").val();
        // this.statsCards[2].title = notes.child("0_power").val();
        // this.statsCards[3].title = notes.child("1_power").val();
         this.timestamp = notes.child("timestamp").val()

       
       
       switch (this.tipo) {
        case 'meter3':
          

        this.phase1 = parseFloat(notes.child("0_power").val());
        this.phase2 = parseFloat(notes.child("1_power").val());
        this.phase3 = parseFloat(notes.child("2_power").val());


          break;

          case 'meter3-400':


        this.phase1 = parseFloat(notes.child("a_act_power").val());
        this.phase2 = parseFloat(notes.child("b_act_power").val());
        this.phase3 = parseFloat(notes.child("c_act_power").val());
          
          break;
       
        default:
        this.phase1 = parseFloat(notes.child("0_power").val());
        this.phase2 = parseFloat(notes.child("1_power").val());


          break;
       }
      

      
        // console.log("ph1",this.phase1);
        // console.log("time",this.timestamp);

      });




    },
    methods: {
        info(info, ip) {

//alert(info);


const mqtt = firebase.functions().httpsCallable('mqtt');

mqtt({ ip: ip, ch: info })
  .then(response => {
    console.log(response);
  })

},
    childMethod: function() {
      /* 
      {string} eventName ==> child-method 
      [...args]  ==>  this.child_msg
      */
      var random = Math.random();
    //  console.log(random);
      this.$emit('cli', random);
    }
  }
  };
  </script>
  <style></style>
  
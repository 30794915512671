<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col-2" v-if="$slots.icon || icon">
        
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon"> <i :class="icon"></i> </slot>
          
        </div>
         <!-- <p  class="card-category">Active: {{ addedcard }}</p> -->
      </div>
<div class="col-6">
            <p class="card-category">Current Power</p>
            <h3 class="card-title">{{power.toFixed(2)}} Watts</h3>
            <h3 class="card-title">${{(power*.17/1000).toFixed(2)}} per hour</h3>
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>

      <div class="col-4" v-if="$slots.content || title || addedcard">
        <div class="numbers">
          <slot>
            <p v-if="meter" class="card-category">Meter: {{ meter[0].split('-')[1] }}</p>
            <p v-if="addedcard" class="card-category">Active: {{ addedcard }}</p>
       <p v-else class="card-category" @click="active(info.uid)" >Active: {{ addedcard }}</p>

        <h3 class="card-title">{{ title }}</h3>
           
          </slot>
        </div>
      </div>
    </div>

      <div class="row">
      <div class="col-5">
            <p class="card-category">Yesterday's Comsuption</p>
            <h3 class="card-title">{{ yest }} KWh - ${{(yest*.17).toFixed(2)}}</h3>
            
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>
        <div class="col-5">
            <p class="card-category">Today's Comsuption</p>
            <h3 class="card-title">{{ today }} KWh - ${{(today*.17).toFixed(2)}}</h3>
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>

        <div class="col-2">
            <p class="card-category">Alarm</p>
            <h3 class="card-title">$ {{ alarm }}</h3>
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>

        </div>
  

  <div class="row">
      <div class="col-4">
            <p class="card-category">Total Savings $</p>
            <h3 class="card-title"> ${{(info.saved).toFixed(2)}}</h3>
            
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>
        <div class="col-4">
            <p class="card-category">Avg. Bill</p>
            <h3 class="card-title">${{info.avg}}</h3>
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>

        <div class="col-4">
            <p class="card-category">Est. Bill</p>
            <h3 class="card-title">${{(30*yest*.17).toFixed(2)}}</h3>
             <!-- <p class="card-category">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>

        </div>
     
     
    <!-- <div class="stats" slot="footer" v-if="$slots.footer">
  
  <div v-if="route">
<router-link :to="route"><slot name="footer"></slot></router-link>
  </div>
  <div v-else>
<slot name="footer"></slot>
  </div>
    </div> -->

      
  </card>
</template>
<script>
import Card from './Card.vue';
import moment from 'moment';
import firebase from '../../Firebase';

export default {
  name: 'stats-user',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    info: Object,
    title: String,
    meter: String,
    addedcard: {
      type: Boolean,
      default: false
    },
    open: Boolean,
    route: String
  },
  mounted: function () {

  this.handle = setInterval(async () => {

  //  console.log("dada:=>"+this.open);

    if(this.open){

   // if(this.timestamp<moment().subtract(2,'minutes').unix()*1000){

 let dt = await firebase.database()
    .ref("smeter").child(this.meter[0]).child("instant")
  .once("value");
//console.log(dt)

//console.log(this.meter[0]+"//"+dt.child("timestamp").val())
this.timestamp = dt.child("timestamp").val()
this.phase1 = parseFloat(dt.child("0_power").val());
this.phase2 = parseFloat(dt.child("1_power").val());

this.power = this.phase1 + this.phase2;

//       }else{
// this.power = "0";
//       }

      }else{
this.yest = 0
this.today = 0
this.alarm = 0
this.power = 0
      }
      
  }, 5000)
},
  async beforeUpdate () {

// this.yest = 0
// this.today = 0
// this.alarm = 0

if(this.meter){
  if(this.open){

  // console.log('beforeUpdate:', this.meter[0])
let test = await this.getEnergy(1,this.meter[0])

let test1 = await this.getEnergy(0,this.meter[0])

let alarm = await this.getAlarm(this.meter[0])

this.yest = test[2]
this.today = test1[2]
this.alarm = alarm

  }
}else{
this.yest = 0
this.today = 0
this.alarm = 0
this.power = 0
}

  
  },
  data() {
    return {
      yest:0,
      today:0,
      alarm:0,
      power:0,
      phase1:0,
      phase2:0,
      timestamp:0
    };
  },
  beforeDestroy: function () {
console.log('dsdsdsd')
  clearInterval(this.handle);
},
  methods: {
    active(info){

      console.log(info)

      firebase.firestore().collection("users").doc(info).update({
        addedcard:true
      })
      
this.addedcard = true




    },
    async getAlarm(meter){

//       var alldata=[];
//  console.log("meterrr:"+meter)
//    console.log(moment().subtract(day,'days').startOf('day'))
     let vinit=0;

 let init = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('alarms').orderByChild("meter").equalTo(meter).once("value");
      
      init.forEach(init1 => {

        if(init1.child("type").val()==='$')
{
  //console.log("$$$:"+init1.child("value").val())
vinit = init1.child("value").val()
}
      });
      

      return vinit;
      
      },
    async getEnergy(day,meter){

      var alldata=[];
//  console.log("meterrr:"+meter)
//    console.log(moment().subtract(day,'days').startOf('day'))
     let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("0_total")
 .orderByChild('timestamp').startAt(moment().subtract(day,'days').startOf('day').unix()*1000-300000)
    .endAt(moment().subtract(day,'days').endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 //.ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("0_total")
 .orderByChild('timestamp').startAt(moment().subtract(day,'days').startOf('day').unix()*1000-300000)
.endAt(moment().subtract(day,'days').endOf('day').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total").val()
      });

 let energytotal = ((vlast-vinit)/1000).toFixed(2);
alldata.push(energytotal);
//////***** */
 let init1 = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("1_total")
  .orderByChild('timestamp').startAt(moment().subtract(day,'days').startOf('day').unix()*1000-300000)
    .endAt(moment().subtract(day,'days').endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init1.forEach(init11 => {
vinit1 = init11.child("1_total").val()

      });
   
  let last1 = await  firebase.database()
 //.ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("1_total")
.orderByChild('timestamp').startAt(moment().subtract(day,'days').startOf('day').unix()*1000-300000)
.endAt(moment().subtract(day,'days').endOf('day').unix()*1000).limitToLast(1).once("value");
   last1.forEach(init11 => {
vlast1 = init11.child("1_total").val()
      });
 let energytotal1 = ((vlast1-vinit1)/1000).toFixed(2);
alldata.push(energytotal1);
let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);


alldata.push(parseFloat(finaltotal.toFixed(2)));

 
       return alldata;
     
    }
  }
};
</script>
<style></style>

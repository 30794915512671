export default {
  colors: {
    default: '#344675',
    primary: '#42b883',//42b883
    info: '#1d8cf8',
    danger: '#fd5d93',//fd5d93
    orange: '#ff8a76',
    teal: '#00d6b4',
    teal2: '#525f7f',
    solar:'#fff019',
    primaryGradient: [
      'rgba(76, 211, 150, 0.1)',
      'rgba(53, 183, 125, 0)',
      'rgba(119,52,169,0)'
    ],
    purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)']
  },
};

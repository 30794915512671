<template>
  <div>
    <div class="card-body text-center">
      <div class="btn-group ">
        <a href="/login-customer" type="button" class="btn btn-info  d-flex align-items-center justify-content-center" style="width: 30%;" >
          Customer
        </a>
        <a href="/login-manager" type="button" class="btn btn-success active d-flex align-items-center justify-content-center" style="width: 30%;">
          Manager
        </a>
        <a href="/login-business-manager" type="button" class="btn btn-info d-flex align-items-center justify-content-center" style="width: 30%;">
          Business Manager
        </a>
      </div>
    </div>
    <div class="container">
      <div class="col-lg-4 col-md-6 ml-auto mr-auto">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-login card-white">
              <template slot="header">
                <img src="img/card-success.png" alt="" />
                <h1 class="card-title">Log in</h1>
              </template>
  
              <div>
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="email"
                  type="email"
                  placeholder="Email"
                  addon-left-icon="tim-icons icon-email-85"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
               </ValidationProvider>
  
               <ValidationProvider
                 name="password"
                 rules="required|min:6"
                 v-slot="{ passed, failed, errors }"
               >
               <base-input
                 required
                 v-model="password"
                 placeholder="Password"
                 addon-left-icon="tim-icons icon-lock-circle"
                 type="password"
                 :error="errors[0]"
                 :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
               </base-input>
              </ValidationProvider>
              </div>
  
              <div slot="footer">
                <base-button native-type="submit" type="success" class="mb-3" size="lg" block>
                  Get Started
                </base-button>
                <div class="pull-left">
                  <h6>
                    <router-link class="link footer-link" to="/register">
                      Create Account
                    </router-link>
                  </h6>
                </div>
  
                <div class="pull-right">
                  <h6>
                    <a href="https://m.me/martinson.machine"  class="link footer-link">Need Help?</a>
                    </h6>
                </div>
              </div>
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
  </template>
  <script>
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";
  import supabase from '@/supabase.js'
  import store from '@/store';
   
  extend("email", email);
  extend("min", min);
  extend("required", required);
  
  export default {
    data() {
      return {
        email: "",
        password: "",
        subscribe: true,
        userType: null // definindo inicialmente como null
      };
    },
  
    methods: {
      submit: async function() {
        const { data, error } = await supabase
          .from('managers')
          .select('email')
          .eq('email', this.email);
  
        if (error) {
          alert("Error: " + error.message);
        } else if (!data || data.length === 0) {
          alert("Error: User not found!");
        } else {
          const { error } = await supabase.auth.signInWithPassword({
            email: this.email,
            password: this.password,
          });
          if (error) {
            alert("Error: " + error.message);
          } else {
            alert("Login successful!");
            store.dispatch('setUserType', 'manager');

            console.log(store.getters.getUserType)
            this.$router.push({ path: '/' });
          }
        }
      },
    }
  };
  </script>
  
  <style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
  </style>
  
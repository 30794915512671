<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(registerToken)">
      <div class="row">
          <div class="form-group col-md-5">
          <ValidationProvider
              name="Email"
              rules="required"
              v-slot="{ passed, failed, errors }">
              <base-input
                  required
                  label="Email"
                  v-model="email"
                  placeholder="Email"
                  type="email"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
          </ValidationProvider>
          </div>
      </div><button  native-type="submit" type="success" class="btn btn-info">Send</button>
  </form>  
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import supabase from '@/supabase.js'
import { v4 as uuidv4 } from "uuid";

extend("required", required);

export default {
data() {
  return {
      email: '',
    };
},
methods: {
  async registerToken(){
      const now = new Date();
      const expiration = new Date(now.getTime() + (48 * 60 * 60 * 1000));
      const token =  uuidv4();
      const { data: { user } } = await supabase.auth.getUser()
      const id_creator = user.id;
      console.log(this.email)
      const { data, error } = await supabase
          .from('token')
          .insert({ token, expiration, id_creator, email:this.email, usertype:'business-manager'})
          .select()
      if (error) {
          console.error(error);
          return;
      }else{
          console.log(data)
          await this.sendEmail(token)
      }
  },
  async sendEmail(token){
      var registerUrl = window.location.origin + '/';
      registerUrl = registerUrl + 'register-business-manager?token=' + token;
      console.log(registerUrl)
  }
}
};
</script>
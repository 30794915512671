<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Admin Management</h2>
      <p class="text-center">
       
       
      </p>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Users</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="90" align="right" label="Actions">
                <div slot-scope="props">
                  <!-- <base-button
                    @click.native="handleLike(props.$index, props.row)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button> -->
                  <base-button
                    @click.native="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <!-- <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button> -->
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal
        :show.sync="searchModalVisible"
        class="modal-search"
      
        id="searchModal2"
        :centered="false"
        :show-close="true"
      >
       <div v-if="info.meter">
         
        

         </div>
         <div v-else>
           <input
          slot="header"
          v-model="meterid"
          type="text"
            color="default"
          class="form-control"
          placeholder="Meter-id"
        />
           </div>
        
        

        <div
          slot="header"
          class="form-control"
          
        />

         <stats-user
        :title="info.firstName"
        :info="info"
        :addedcard="info.addedcard"
        :meter="info.meter"
        :open="searchModalVisible"
        type="warning"
        icon="tim-icons icon-single-02"
      >
      </stats-user>
      
         <div v-if="info.meter">

         </div>
         <div v-else>
<base-button class="animation-on-hover" 
          type="success"
             
                @click.native="addpost()"
        >Add Meter</base-button>
         </div>
        
      </modal>

    </div>

    
</template>
<script>

import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination ,Modal} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import firebase from '../../Firebase';
import StatsUser from 'src/components/Cards/StatsUser';

export default {
  components: {
    StatsUser,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      id:0,
      meterid:'',
      info:{},
       searchModalVisible: false,
      searchQuery: '',
      propsToSearch: ['firstName', 'email', 'postalCode'],
      tableColumns: [
        {
          prop: 'firstName',
          label: 'Name',
          minWidth: 100
        },
        {
          prop: 'email',
          label: 'Email',
          minWidth: 250
        },
        {
          prop: 'postalCode',
          label: 'ZIP',
          minWidth: 100
        },
        {
          prop: 'avg',
          label: 'AVG. Bill',
          minWidth: 100
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    addpost(){

      this.tableData[this.id].meter=[`shellyem-${this.meterid}`]

firebase.firestore().collection("users").doc(this.tableData[this.id].uid).update({
        meter:[`shellyem-${this.meterid}`]
      })


this.meterid = ''
//console.log( this.tableData[this.id])
    },

    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.firstName}`,
        buttonsStyling: false,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });
    },
    handleEdit(index, row) {
        this.id= index;
      this.searchModalVisible = true

      this.info = row
      // swal.fire({
      //   title: `You want to edit ${row.firstName}`,
      //   buttonsStyling: false,
      //   customClass: {
      //     confirmButton: 'btn btn-info btn-fill'
      //   }
      // });
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal.fire({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    
  },
  mounted() {
    // Fuse search initialization.
    

firebase.firestore().collection("users")
        .get()
        .then((doc) => {
          
      doc.docs.forEach(note => {
  
  // console.log(note.id, " => ", note.data());

  this.tableData.push(note.data())


      });

      this.fuseSearch = new Fuse(this.tableData, {
      keys: ['firstName', 'email','postalCode'],
      threshold: 0.3
    });

            
         
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
    //  console.log(value);
       this.searchedData = []
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);

      //  console.log(result);

        result.forEach(note => {
  
  //console.log(note.id, " => ", note.item);

  this.searchedData.push(note.item)


      });
      }
        

     // this.searchedData = result;
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}.el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
</style>



<template>
  <div class="row">
        <div class="col-md-8">
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <card footer-classes="text-right">
  
        <div slot="header"><h4 class="card-title">New Building</h4></div>
        <div>
        <div class="row">
            <div class="col-md-12">
            <ValidationProvider
                    name="Name"
                    rules="required"
                    v-slot="{ passed, failed, errors }">
                <base-input
                    required
                    label="Name"
                    v-model="name"
                    placeholder="Name"
                    type="text"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
            </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5"> 
                <ValidationProvider
                    name="Street"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                    >
                <base-input 
                    required
                    label="Street" 
                    type="text" 
                    placeholder="Street"
                    v-model="street"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
            </ValidationProvider>
            </div>
            <div class="col-md-3">  
                <ValidationProvider
                    name="Number"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                    >
                <base-input
                    label="Number"
                    type="number"
                    placeholder="Number"
                    v-model="number"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
            </div>   
            <div class="col-md-4"> 
                <ValidationProvider
                    name="Neighborhood"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                    >
                <base-input 
                    label="Neighborhood" 
                    type="text" 
                    placeholder="Neighborhood"
                    v-model="neighborhood"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-5"> 
                <ValidationProvider
                name="City"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input 
                    label="City" 
                    type="text" 
                    placeholder="City"
                    v-model="city"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
            </div>
            <div class="col-md-3"> 
                <ValidationProvider
                name="State"
                rules="required"
                v-slot="{ passed, failed, errors }"
                > 
                <base-input
                    label="State"
                    type="text"
                    placeholder="State"
                    v-model="state"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
            </div>   
            <div class="col-md-4"> 
                <ValidationProvider
                name="Zip code"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input 
                    label="Zip code" 
                    type="text" 
                    placeholder="Zip code"
                    v-model="zipcode"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>   
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-6"> 
                <ValidationProvider
                name="Total units"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input 
                    label="Total units" 
                    type="number" 
                    placeholder="Total units"
                    v-model="total_units"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
            </div>
            <div class="col-md-6"> 
                <ValidationProvider
                name="Total subdivision"
                rules="required"
                v-slot="{ passed, failed, errors }"
                > 
                <base-input
                    label="Total subdivision"
                    type="number"
                    placeholder="Total subdivision"
                    v-model="total_subdivisions"
                    required
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
            </div>   
        </div>
        <base-button  class="mt-3" native-type="submit" type="primary"
            >Save</base-button>
      </div>
      <template slot="footer" class="text-right">
      </template>
      
    </card>
  </form>
  </ValidationObserver>
</div>
    <div class="col-md-4">
    <div style="max-height: 435px; overflow-y: auto;">
    <table class="table table-hover" style="background-color: #27293D;">
      <thead class="sticky-top"> 
        <tr>
          <th style="background-color: #27293D;">Buildings</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="building in buildings" :key="building.id">
          <td>{{ building.name }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>
</div>

   

</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import supabase from '@/supabase.js'

extend("required", required);

export default {
 
    data() {
    return {
        buildings: [],
        name: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        zipcode: '',
        total_units: '',
        total_subdivisions: ''
    };
},
    mounted(){
        this.loadBuildings();
    },

    methods: {   


    async loadBuildings() {

    const { data: { user }, error } = await supabase.auth.getUser();

    if (error) {
        console.error(error);
        return;
    }
    const userId = user.id;
        console.log('User ID:', userId);

    const { data: permissionData, error: permissionError } = await supabase
        .from('permissionbusinessmanagers')
        .select('id_building')
        .eq('id_businessmanager', userId);

    if (permissionError) {
        throw permissionError;
    }

    const { data: buildingData, error: buildingError } = await supabase
        .from('buildings')
        .select('*')
        .in('id', permissionData.map(permission => permission.id_building));

    if (buildingError) {
        throw buildingError;
    }

    this.buildings = buildingData.map(building => ({
        ...building,
    }));

  },

    async submit() {
    const { data: { user }, error } = await supabase.auth.getUser();

    if (error) {
        console.error(error);
        return;
    }
    const userId = user.id;
    console.log('User ID:', userId);

    const { data: buildingData, error: buildingError } = await supabase
        .from('buildings')
        .select('id')
        .eq('name', this.name);

        if (buildingError) {
        throw buildingError;
        }

        if (buildingData.length > 0) {
            alert('Building name already exists!');
            return;
        }

        // Insert the new building
        const { data: newBuilding, error: errorInsert } = await supabase
            .from('buildings')
            .insert({
            name: this.name,
            street: this.street,
            number: this.number,
            neighborhood: this.neighborhood,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            total_units: this.total_units,
            total_subdivisions: this.total_subdivisions
            })
            .select();

        if (errorInsert) {
            console.log(errorInsert);
            return;
        }
        console.log(newBuilding)
        const buildingId = newBuilding[0].id;
        console.log('New building ID:', buildingId);

        const { error: errorInsertPerm } = await supabase
            .from('permissionbusinessmanagers')
            .insert({
            id_building: buildingId,
            id_businessmanager: userId
            });

        if (errorInsertPerm) {
            console.log(errorInsertPerm);
            return;
        }

        console.log('Dados inseridos com sucesso');
        this.loadBuildings();
        },

    
}

};
</script>
<style></style>

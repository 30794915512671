<template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(registerToken)">
        <div class="row">
          <div class="form-group col-md-5">
            <ValidationProvider
              name="Email"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                required
                label="Email"
                v-model="email"
                placeholder="Email"
                type="email"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>
          <div class="form-group col-md-4">
            <ValidationProvider
              name="Building"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <label>Building</label>
              <el-select
                required
                type="select"
                placeholder="Select Building"
                v-model="selects.building"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              >
                <el-option
                  v-for="option in selects.buildings"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                ></el-option>
              </el-select>
            </ValidationProvider>
          </div>
        </div>
        <button  native-type="submit" type="success" class="btn btn-info">Send</button>
      </form>
    </ValidationObserver>
  </template>
  
  <script>
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import { Select, Option } from "element-ui";
  import supabase from "@/supabase.js";
  import { v4 as uuidv4 } from "uuid";
  
  extend("required", required);
  
  export default {
    components: {
      [Option.name]: Option,
      [Select.name]: Select,
    },
  
    data() {
      return {
        email: "",
        selects: {
          building: "",
          buildings: [],
        },
      };
    },
  
    async created() {
      await this.getBuildings();
    },
  
    methods: {
    async getBuildings() {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
            console.error(error);
            return;
        }
        const userId = user.id;
        //console.log('User ID:', userId);
        //console.log(user)

        if (user.user_metadata.userType.includes('business-manager')) {
        const { data: permissionData, error: permissionError } = await supabase
          .from("permissionbusinessmanagers")
          .select("id_building")
          .eq("id_businessmanager", userId);
  
            if (permissionError) {
            throw permissionError;
            }
  
        const { data: buildingData, error: buildingError } = await supabase
          .from("buildings")
          .select("*")
          .in("id", permissionData.map((permission) => permission.id_building));
  
            if (buildingError) {
            throw buildingError;
            }
  
        this.selects.buildings = buildingData.map((building) => ({
          value: building.id,
          label: building.name,
        }));
        } else {
            const { data: permissionData, error: permissionError } = await supabase
                .from("managers")
                .select("id_building")
                .eq("id", userId);
    
                if (permissionError) {
                throw permissionError;
                }
            const id_building = permissionData[0].id_building;
            //console.log(id_building)
            const { data: buildingData, error: buildingError } = await supabase
                .from("buildings")
                .select("*")
                .eq("id", id_building);
    
                if (buildingError) {
                throw buildingError;
                }
  
            this.selects.buildings = buildingData.map((building) => ({
                value: building.id,
                label: building.name,
            }));
        }

      },
  
    async registerToken(){
        console.log(this.selects.building);
        const id_building = this.selects.building;
        const now = new Date();
        const expiration = new Date(now.getTime() + (48 * 60 * 60 * 1000));
        const token =  uuidv4();
        const { data: { user } } = await supabase.auth.getUser()
        const id_creator = user.id;
        console.log(this.email)
        const { data, error } = await supabase
            .from('token')
            .insert({ token, expiration, id_creator, email:this.email,id_building, usertype:'customer'})
            .select()
        if (error) {
            console.error(error);
            return;
        }else{
            console.log(data)
            await this.sendEmail(token)
        }
    },

    async sendEmail(token){
        var registerUrl = window.location.origin + '/';
        registerUrl = registerUrl + 'register-customer?token=' + token;
        console.log(registerUrl)
        this.email = '';
        this.selects.building = '';
    }
    },
  };
  </script>
  
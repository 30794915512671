<template>
  <div class="row">
    <!-- <div class="col-md-12">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Striped table</h4>
        <el-table :data="data" :key="componentKey">
          <el-table-column
            min-width="150"
            sortable
            label="day"
            property="day"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            label="energy"
            property="energy"
          ></el-table-column>
         
        </el-table>
      </card>
    </div> -->
    <div class="col-md-6">
        <card>
          <template slot="header">
            <h5 class="card-category">Meters</h5>
            <h3 class="card-title">Help</h3>
          </template>

          <tabs type="success" vertical class="row">

            <tab-pane>

            <span slot="label"
              v-on:click="change(0)">
                Meter
              </span>
              The energy meter is reponsable to measure how much has been consumpted throght it.
              <br /><br />
              Make sure to selected the right meter to each location.
            </tab-pane>

            <tab-pane>
            <span slot="label"
              v-on:click="change(1)">
              Device
              </span>
              Normally we only have one meter per location, but in case you have to add more rooms use the form. <br /><br />
              Each Meter can have multiples devices.
            </tab-pane>

            <!-- <tab-pane>
            <span slot="label"
              v-on:click="change(2)">
              Device
              </span>
              Normally we only have one meter per location, but in case you have to add more rooms use the form. <br /><br />
              Each Meter can have multiples devices.
            </tab-pane> -->

           

            <!-- <tab-pane label="Automated">
              Automated reading is comming soon... <br /><br />.
            </tab-pane> -->
          </tabs>
        </card>
      </div>
    <div class="col-md-6">
      <card title="Horizontal Form" v-if="mode===0">
        <h4 slot="header" class="card-title">Meter</h4>
        <form class="form-horizontal" action="#" method="#" @submit.prevent>
          <div class="row" >

            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
              <base-input v-model="name" placeholder="name"> </base-input>
            </div>

            <label class="col-md-3 col-form-label">Meter ID</label>
            <div class="col-md-9">
              <base-input v-model="meterid" placeholder="meter_id"> </base-input>
            </div>

            <label class="col-md-3 col-form-label">Cost Kwh</label>
            <div class="col-md-9">
              <base-input v-model="cost" placeholder="kwh"> </base-input>
            </div>



            
            <label class="col-sm-3 col-form-label">Other User</label>
            <div class="col-sm-9 checkbox-radios">

              
              
              <base-checkbox
               v-model="othercli"
             
              ></base-checkbox>
           
            </div>
            
            <!-- <div class="col-md-12" v-if="othercli"> -->


            <label class="col-md-3 col-form-label" v-if="othercli">User</label>
            <div class="col-md-9" v-if="othercli">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder=">>"
                  v-model="uid"
                >
                  <el-option
                    v-for="option in userData"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <br />
              <br />
              <br />
            <!-- </div> -->
            
          </div>

          

          <!-- <div class="row">
            <label class="col-md-3 col-form-label">Email</label>
            <div class="col-md-9">
              <base-input placeholder="Email" type="email"> </base-input>
            </div>
          </div>

          <div class="row">
            <label class="col-md-3 col-form-label">Password</label>
            <div class="col-md-9">
              <base-input placeholder="Password" type="password"> </base-input>
            </div>
          </div> -->

        </form>
        <div class="row">
          <div class="col-md-9 offset-md-3 col-12">
            <base-button  @click.native="addmeter()" class="mt-3" native-type="submit" type="success">
              Add Meter
            </base-button>
          </div>
        </div>
      </card>


      <card title="Horizontal Form" v-else>
        <h4 slot="header" class="card-title">Device</h4>
        <form class="form-horizontal" action="#" method="#" @submit.prevent>
          <div class="row" >

            <label class="col-md-3 col-form-label">Name</label>
            <div class="col-md-9">
              <base-input v-model="devicename" placeholder="name"> </base-input>
            </div>

            <label class="col-md-3 col-form-label">Device ID</label>
            <div class="col-md-9">
              <base-input v-model="device" placeholder="device_id"> </base-input>
            </div>

            <label class="col-md-3 col-form-label">Meter ID</label>
            <div class="col-md-9">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder=">>"
                  v-model="dmeterid"
                >
                  <el-option
                    v-for="option in tableData"
                    class="select-primary"
                    :value="option.name"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <br />
              <br />
              <br />

            <label class="col-md-3 col-form-label">Num</label>
            <div class="col-md-9">
              <base-input v-model="num" placeholder="num"> </base-input>
            </div>

            <label class="col-sm-3 col-form-label">Priority</label>
            <div class="col-sm-8 checkbox-radios">
              
              <base-checkbox v-model="pri"></base-checkbox>
           
            </div>

           

            
          </div>


        </form>
        <div class="row">
          <div class="col-md-9 offset-md-3 col-12">
            <base-button  @click.native="adddevice()" class="mt-3" native-type="submit" type="success">
              Add Device
            </base-button>
          </div>
        </div>
      </card>
    </div>
    <!-- <div class="col-md-6">
      <card title="Horizontal Form">
        <h4 slot="header" class="card-title">Horizontal Form</h4>
       
         <el-table :data="data" :key="componentKey">
          <el-table-column
            min-width="150"
            sortable
            label="day"
            property="day"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            label="energy"
            property="energy"
          ></el-table-column>
          </el-table>
      </card>
    </div> -->
    <card v-if="mode===0">
      <div slot="header"><h4 class="card-title">Meters</h4></div>
     <div class="row">
     <div class="col-sm-12">
          <el-table :data="tableData" :key="componentKey">
            <!-- <el-table-column min-width="50" label="#" align="center">
              <div class="photo" slot-scope="{ row }">
                <img :src="row.img" alt="Table image" />
              </div>
            </el-table-column> -->
             
            <el-table-column min-width="150" prop="username" label="User">
              
            </el-table-column>

            <el-table-column min-width="150" prop="name" label="Meter ID">
              
            </el-table-column>

            <el-table-column min-width="70" prop="desc" label="Desc.">
              
            </el-table-column>
           
            <!-- <el-table-column min-width="200" prop="job" label="Job Position">
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="since"
              align="center"
              label="Since"
            >
          
            </el-table-column>  -->
            <!-- <el-table-column
              min-width="150"
             prop="timestamp"
              align="center"
              label="Created"
            >
            </el-table-column>  -->


 <!-- <el-table-column
              min-width="150"
              header-align="right"
              align="right"
              label="detail"
            >

 <div
                slot-scope="{ row }"
                class="text-right table-actions"
              >
               
                <el-tooltip
                  content="Show Meter"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="row.id > 2 ? 'danger' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                    @click.native="gotometer(row.name)"
                  >
                  
                    <i class="tim-icons icon-double-right"></i>
                  </base-button>
                </el-tooltip>
              </div>

              </el-table-column> -->

            <el-table-column
              min-width="150"
              header-align="right"
              align="right"
              label="Actions"
            >

              <div
                slot-scope="{ row }"
                class="text-right table-actions"
              >
                <!-- <el-tooltip
                  content="Refresh"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="$index > 2 ? 'success' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                  >
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip> -->
                 <el-tooltip
                 content="Show Meter"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="row.id > 2 ? 'danger' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                     @click.native="gotometer(row.id)"
                  >
                  
                   <i class="tim-icons icon-double-right"></i>
                  </base-button>
                </el-tooltip>
                
                <el-tooltip
                  content="Delete"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="row.id > 2 ? 'danger' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                    @click.native="showSwal('delete',row.id)"
                  >
                  
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
</div>
 
    </card>

    <card v-else>
      <div slot="header"><h4 class="card-title">Devices</h4></div>
     <div class="row">
     <div class="col-sm-12">
          <el-table :data="DeviceData" :key="componentKey1">
            <!-- <el-table-column min-width="50" label="#" align="center">
              <div class="photo" slot-scope="{ row }">
                <img :src="row.img" alt="Table image" />
              </div>
            </el-table-column> -->

            <el-table-column min-width="150" prop="desc" label="Name">
              
              
            </el-table-column>

            <el-table-column min-width="150" prop="device" label="Device ID">
              
              
            </el-table-column>
             

            <el-table-column min-width="150" prop="device_code" label="Meter ID">
              
              
            </el-table-column>

             <!-- <el-table-column min-width="50" prop="priority" label="Priority">
              
              
            </el-table-column> -->


           
            <!-- <el-table-column min-width="200" prop="job" label="Job Position">
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="since"
              align="center"
              label="Since"
            >
          
            </el-table-column>  -->
            <!-- <el-table-column
              min-width="150"
             prop="timestamp"
              align="center"
              label="Created"
            >
            </el-table-column>  -->

            <el-table-column
              min-width="150"
              header-align="left"
              align="left"
              label="Created"
            >

 <div
                slot-scope="{ row }"
              
              >
               
                
              <i class="ti-time">{{row.timestamp}}</i> 
                   
                
               
              </div>

              </el-table-column>

 <el-table-column
              min-width="50"
              header-align="left"
              align="left"
              label="Priority"
            >

 <div
                slot-scope="{ row }"
                class="text-left table-actions"
              >
               
                
              <base-checkbox  v-model="row.priority" disabled></base-checkbox>
                   
                
               
              </div>

              </el-table-column>

            <el-table-column
              min-width="50"
              header-align="right"
              align="right"
              label="Actions"
            >

              <div
                slot-scope="{ row }"
                class="text-right table-actions"
              >
                <!-- <el-tooltip
                  content="Refresh"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="$index > 2 ? 'success' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                  >
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip> -->
                 
                
                <el-tooltip
                  content="Delete"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="row.id > 2 ? 'danger' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                    @click.native="deletedevice('delete',row.id)"
                  >
                  
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
</div>
 
    </card>
    <!-- <div class="col-md-12">
      <card class="card-plain" body-classes="table-full-width">
        <template slot="header">
          <h4 class="card-title">Table on Plain Background</h4>
          <p class="category">Here is a subtitle for this table</p>
        </template>
        <el-table
          header-cell-class-name="table-transparent"
          header-row-class-name="table-transparent"
          row-class-name="table-transparent"
          :data="tableData"
        >
          <el-table-column
            min-width="150"
            sortable
            label="Name"
            property="name"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            label="Country"
            property="country"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            label="City"
            property="city"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            align="right"
            header-align="right"
            label="Salary"
            property="salary"
          ></el-table-column>
        </el-table>
      </card>
    </div> -->

    <!-- <div class="col-md-12">
      <card body-classes="table-full-width">
        <h4 slot="header" class="card-title">Regular Table with Colors</h4>
        <el-table :row-class-name="tableRowClassName" :data="tableData">
          <el-table-column
            min-width="150"
            sortable
            label="Name"
            property="name"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            label="Country"
            property="country"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            label="City"
            property="city"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            align="right"
            header-align="right"
            label="Salary"
            property="salary"
          ></el-table-column>
        </el-table>
      </card>
    </div> -->
  </div>
</template>
<script>
import { Table, TableColumn , Select, Option } from 'element-ui';
import { TabPane, Tabs} from 'src/components';//, Collapse, CollapseItem 
import firebase from '../../Firebase';
//import moment from 'moment';
import swal from 'sweetalert2';

import supabase from '@/supabase.js'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    TabPane,
    Tabs,
  },
  data() {
    return {
      uid:"",
       componentKey: 0,
       componentKey1: 0,
      id:0,
      mode:0,
      meterid:"",
      dmeterid:"",
        name:"",
        cost:"",
        pri:false,
        othercli:false,
        device:"",
        devicename:"",
        num:"",
      usu:[],
     energyf:{},
     energy:{},
     day:{},
       tableData: [],
       userData: [],
       DeviceData: []
    };
  },
  watch: {
    async othercli(value) {
      console.log("entou",value);

      if(value){
        const { data } = await supabase
  .from('customers')
  .select('id,name,phone');

  var users = data;

  
  console.log(data);


  if(users.length>=1){
        console.log("here");
      this.userData= [];
      users.forEach(note => {
        console.log(note);
  this.userData.push({
    id:note.id,
name:note.name,
desc:note.phone,
  })

      });

      }

      }else{

        this.uid=this.$user.id;
      }

      

      }

    },
 async mounted() {

  this.uid = this.$user.id;

  
  this.readmeters(this.uid);


   },
  methods: {
    async cai(){
    setTimeout(function(){
        return "Test";
    },1000);
    return undefined; // line is basically what is here when you don't return anything
},

    async change(index){

      this.mode=index;

if(index===1&&this.DeviceData.length===0){

  this.readDevices();
}


    console.log(index);
    },

    gotometer(info){

     this.$router.push({ path: '/meters/'+info  })

console.log(info);
    },
    async readmeters(uid) {
      
      const { data } = await supabase
  .from('customers')
  .select('id,name, Devices(id,device_code,desc)')
  .or('type.eq.meter', { foreignTable: 'Devices' })
  //.eq('type', 'meter')

  var user = data;

  console.log("-------!!!!!------");
console.log(data);

// this.usu = this.$user.info;
// console.log("-------!!!!!------");
// console.log(this.usu);
// var trying = 0;
// while(trying<5){ 
//   console.log("-------load again------");
//   this.usu = this.$user.info;
//   console.log('user',this.usu);
//   console.log('from',this.$user);
//   await this.cai();
//   trying++;
// }


// console.log("-------!!!!!------");
// console.log(user);

// console.log("-------!!!!!------");

this.tableData= [];
user.forEach(info=>{


info.Devices.forEach(note => {
        //console.log(note);
  this.tableData.push({
    username:info.name,
    userid:info.id,
    id:note.id,
name:note.device_code,
desc:note.desc,
timestamp:0
  })

      });



})

//       if(user[0].Devices.length>=1){
//         console.log("here");
//       this.tableData= [];
//       user[0].Devices.forEach(note => {
//         //console.log(note);
//   this.tableData.push({
//     id:note.id,
// name:note.device_code,
// desc:note.desc,
// timestamp:0
//   })

//       });

//       }
      

        this.forceRerender();
    },
    
    async readDevices() {
      this.DeviceData= [];

        const { data } = await supabase
  .from('Devices')
  .select('id,device_code,desc,num,priority,timestamp,device')
  .eq('type','device')
    
            console.log("info devices: ", data);
             var devices = data;
           devices.forEach(device=>{

            console.log("device: ", device);
  
  this.DeviceData.push({
    id:device.id,
    desc:device.desc,
    device:device.device,
num:device.num,
device_code:device.device_code,
priority:device.priority,
timestamp:device.timestamp
  })

      });      
         this.forceRerender1();
          // return employeesData
       
    },
     showSwal(type,id) {

if (type === 'delete') {
        swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this location/meters!',
          icon: 'warning',
          showCancelButton: true,
           buttons: true,
  dangerMode: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        }).then(
          async (willDelete) => 
          {
         //   console.log(id);
            
          
            if(willDelete.value){

       
        const { error } = await supabase
          .from("Devices")
          .delete()
          .eq("id", id);

        if (error) {
          throw error;
        }


this.readmeters(this.uid);

swal.fire({
              title: 'Deleted!',
              text: 'Your meter info has been deleted.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              buttonsStyling: false
            });
            }else{
                      swal.fire({
                title: 'Cancelled',
                text: 'Your meter info is safe :)',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-info btn-fill'
                },
                buttonsStyling: false
              });
            }
          //   dismiss => {
          //   // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
          //   if (dismiss === 'close') {
          //     swal.fire({
          //       title: 'Cancelled',
          //       text: 'Your imaginary file is safe :)',
          //       icon: 'error',
          //       customClass: {
          //         confirmButton: 'btn btn-info btn-fill'
          //       },
          //       buttonsStyling: false
          //     });
          //   }
          // },
            
          }
        );
      }


     },
   async  deletedevice(type,id) {

if (type === 'delete') {
        swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this device!',
          icon: 'warning',
          showCancelButton: true,
           buttons: true,
  dangerMode: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        }).then(
          (willDelete) => 
          {
         //   console.log(id);
            
          
            if(willDelete.value){

    // firebase.database()
    // .ref('location')
    // .child( this.uid)
    // .child(id)
    // .remove();

     firebase.firestore().collection("devices").doc(id).delete();
this.readDevices();

swal.fire({
              title: 'Deleted!',
              text: 'Your Device has been deleted.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              buttonsStyling: false
            });
            }else{
                      swal.fire({
                title: 'Cancelled',
                text: 'Your device info is safe :)',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-info btn-fill'
                },
                buttonsStyling: false
              });
            }
          //   dismiss => {
          //   // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
          //   if (dismiss === 'close') {
          //     swal.fire({
          //       title: 'Cancelled',
          //       text: 'Your imaginary file is safe :)',
          //       icon: 'error',
          //       customClass: {
          //         confirmButton: 'btn btn-info btn-fill'
          //       },
          //       buttonsStyling: false
          //     });
          //   }
          // },
            
          }
        );
      }


     },

     async adddevice() {
     

        const { data, error } = await supabase
          .from("Devices")
          .insert([
            {
       desc:this.devicename,
        num:this.num,
        device_code:this.dmeterid,
        device:this.device,
        priority:this.pri,
        user_id:this.uid,
        type:'device'
             
            },
          ]);
      

this.devicename="";
      this.num="";
      this.dmeterid="";
      this.pri=false;
     
       

      
     // this.location="";
         this.readDevices();
    },

    async addmeter() {
      console.log(this.meterid);

      // firebase.database().ref("location").child(this.uid).push({
      //   loc: this.location,
      //   timestamp:moment().unix()*1000
      // });
      // firebase.firestore().collection("users").doc(this.uid).update({
      //   meter: firebase.firestore.FieldValue.arrayUnion(this.location)
      // })

      const { data, error } = await supabase
          .from("Devices")
          .insert([
            {
              user_id: this.uid,
              desc: this.name,
              device_code: this.meterid,
              price: this.cost,
              type:'meter'
             
            },
          ]);


      this.meterid="";
      this.name="";
      this.cost="";
      this.uid=this.$user.id;
      this.othercli =false;

         this.readmeters(this.uid);
    },
    timeConverter(UNIX_timestamp){
    //  console.log(UNIX_timestamp);
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},
    forceRerender() {
      console.log(" render ");
      this.componentKey += 1;
    },
    forceRerender1() {
      console.log(" render ");
      this.componentKey1 += 1;
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 0) {
        return 'table-success';
      } else if (rowIndex === 2) {
        return 'table-info';
      } else if (rowIndex === 4) {
        return 'table-danger';
      } else if (rowIndex === 6) {
        return 'table-warning';
      }
      return '';
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}.el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
</style>

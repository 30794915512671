<template>
  <card class="card-user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      
        <h5 class="title">Payment</h5>

        <v-row  v-if="adding" align="center"
      justify="center">
      <v-col>
      <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
      </v-col>
  </v-row>
    
    <div v-if="block">

    
       <base-button 
       :type=type
        @click.native="checkout()">
       <div v-if="newcard">
Add new card
       </div>
       <div v-else>
Cancel
       </div>
      </base-button>
</div>
    </div>
    <p></p>

    <div v-if="newcard">
<p class="description">{{brand}} {{last4}} </p>
    <div class="card-description">
      <div v-if="lstpay">
         Last Payment was {{lstpay}}

         <base-button 
       type=danger
        @click.native="cancel()">
       Cancel plan
      </base-button>
         </div>

         <!-- <div v-else>
           <base-button 
       type=success
        @click.native="start()">
       Start plan
      </base-button>
        </div> -->
    </div>
    </div>
    <div v-else>
<stripe-elements
      ref="elementsRef"
      :pk="publishableKey"
      :amount="amount"
      locale="en"
      @token="tokenCreated"
      @loading="loading = $event"
    >
    </stripe-elements>

    <base-button 
       type="success"
        @click.native="save()">
       Save
      </base-button>

    </div>

    

    <!-- <div slot="footer" class="button-container">
      <base-button class="btn-facebook" icon round>
        <i class="fab fa-facebook"></i>
      </base-button>
      <base-button class="btn-twitter" icon round>
        <i class="fab fa-twitter"></i>
      </base-button>
      <base-button class="btn-google" icon round>
        <i class="fab fa-google-plus"></i>
      </base-button>
    </div> -->
  </card>
</template>
<script>
import firebase from '../../../Firebase';
import { StripeElements } from 'vue-stripe-checkout';
import swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
//const axios = require('axios');
//import  axios from 'axios';

export default {
  components: {
    StripeElements,
    PulseLoader
   
  },
  data() {
    return {
      adding:false,
      block:false,
       last4:"",
       brand:"",
       uid:"",
       stripe_id:"",
       type:"info",
       loading: true,
    amount: 2000,
    publishableKey: "pk_test_FnMjHuXBPVvHYld6tvKDrp3S" ,
    token: null,
    charge: null,
      newcard:false,
        lstpay:"",
     // ref: firebase.firestore().collection('users'),
    };
  },
  created(){
    console.log("#################");
      const currentUser = firebase.auth().currentUser
  this.uid = currentUser.uid;

  const ref = firebase.firestore().collection('users').doc(currentUser.uid);
    ref.onSnapshot((doc) => {
       if (doc.exists) {
         this.$user = doc.data();
        this.uid = doc.data().uid;
        this.stripe_id = doc.data().stripe_id;
        if(doc.data().card.last4){
        this.last4=doc.data().card.last4;
       this.brand= doc.data().card.brand;
       this.lstpay = doc.data().lstpay;
      //  this.stripe_id = doc.data().stripe_id;
      //  this.block =true;
      //   this.newcard =true;
        }else{
          // this.newcard =false;
          // this.block =false;
        }
        
        console.log(doc.data())
      } 
    });

    

  
  },
   methods: {
     start(){

      //  firebase.firestore().collection('users').doc(this.uid).update({
      //   card:{last4:'',
      //  brand:'',
      //  id:''}
      //  })

firebase.firestore().collection('users').doc(this.uid).collection('plan').add({
         stripe_id:this.stripe_id,
         timestamp:111111,
         type:"start"
       })

     },
     cancel(){

       firebase.firestore().collection('users').doc(this.uid).update({
        lstpay:""
       })

     },
     save () {
      this.adding=true;

  this.$refs.elementsRef.submit();
    },
    notifyVue(type,msg,timer,error) {
      swal.fire({
  icon: type,
  title: 'Card info',
  text: msg,
  timer: timer,
  footer: error?'':'try again!'
})
     
    },
     tokenCreated (token) {
      this.adding=true;
      this.token = token;
console.log(token)
       console.log(token.card.brand+" "+token.card.last4)
       firebase.firestore().collection('users').doc(this.uid).update({
         card:{last4:token.card.last4, brand:token.card.brand, id:token.id, }
       })

       const add = firebase.functions().httpsCallable('AddCard');

       add({ stripe_id:  this.stripe_id, userId:this.uid, token:token.id })
      .then(response => {
        console.log(response);
        
        this.adding=false;
       if(response.data.card){
        this.notifyVue('success',response.data.msg,2500,true);

        firebase.firestore().collection('users').doc(this.uid).collection('cards').add({
         last4:token.card.last4, brand:token.card.brand, token:token.id
        })
        this.block =true;
        this.newcard =true;
       }else{
        this.notifyVue('error',response.data.msg,10000,false);
        this.newcard =false;
          this.block =false;


       }
      


      })



       
      // this.checkout()
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      // this.charge = {
      //   source: token.id,
      //   amount: this.amount, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
      //   description: this.description // optional description that will show up on stripe when looking at payments
      // }
     // this.sendTokenToServer(this.charge);
    },

      checkout() {

        this.newcard = !this.newcard;

        this.type = this.newcard? "info":"danger"

       

        // alert(this.newcard);
      },
    updateProfile() {
     // pk_test_FnMjHuXBPVvHYld6tvKDrp3S
      alert('Your profile has been uptaded!');

      firebase.database().ref('users').child(this.uid).update(this.user)
      firebase.firestore().collection('users').doc(this.uid).update(this.user)

      // firebase.database().ref('users').child("Rr46dFQvIZWVbad7ulsMs1ArwtN2").remove();
      
    }
  }

};
</script>
<style>
.card .alert {
  position: relative !important;
  width: 100%;
}

.modal-body, .modal-footer {
  padding: 24px 24px 16px 24px;
}

</style>

<template>
  <div>
    <!-- <div class="row"><route-bread-crumb /></div> -->
    <card>
      <div slot="header"><h4 class="card-title">Top 30 energy savers</h4></div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="table" :key="componentKey">
            <el-table-column min-width="50" label="#" align="center">
              <div slot-scope="{ row, $index }">
                {{$index+1}}
              </div>
            </el-table-column>
            <el-table-column min-width="150" prop="name" label="Name">
            </el-table-column>

          
            <el-table-column
              min-width="150"
              prop="saved"
              align="right"
              label="savings"
            >
            </el-table-column>

               <el-table-column label="Milestone" min-width="130">
              <div slot-scope="{ row }">
                <base-progress
                  :value="row.progress"
                  :label="row.progressText"
                  value-position="right"
                />
              </div>
            </el-table-column>

            <el-table-column
              min-width="150"
              header-align="right"
              align="right"
              label="Likes"
            >
              <div
                slot-scope="{ row }"
                class="text-right table-actions"
              >
                {{row.like}}
                <el-tooltip
                  content="Like"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                  @click.native="handleLike(row.key)"
                    :type="row.liked? 'success' : 'info'"
                    icon
                    size="sm"
                    class="btn-link"
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
   
   
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import firebase from '../../Firebase';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    RouteBreadCrumb,
    BaseProgress,
  },
  data() {
    return {
      componentKey: 0,
       liked: [],
      table: [],
       uid:"",
    };
  },
  
  created(){

 const currentUser = firebase.auth().currentUser
  this.uid = currentUser.uid;

 firebase.database().ref('users')
    .orderByChild('saved').limitToLast(30).on('value', users =>{
this.table=[]
users.forEach(user=>{
  let like =parseInt(user.child("like").val())>0?user.child("like").val():0;
  let liked=this.liked.includes(user.ref.key)
this.table.push({
          key:user.ref.key,
          name:user.child("firstName").val()+" "+user.child("lastName").val(),
          uid:user.ref.key,
          // progressText:user.child("size").val(),
          saved: this.formatPrice(user.child("saved").val()),
          like: like,
          liked: liked,
          progress: (100*(user.child("saved").val()/user.child("goal").val())).toFixed(2),
          icon:user.child("icon").val(),
})
})
this.table.sort((a, b) => a.saved - b.saved );
this.table.reverse();

 

    })

     this.checklike();
 //this.formatPrice(
 



  },
  
  methods:{
    checklike(){
      this.table.forEach(async (post,index) => {
let wasliked= await firebase.database().ref('users')
.child(this.uid)
.child("liked").child(post.key).once("value");

 this.table[index].liked = wasliked.numChildren()>0?true:false
if(wasliked.numChildren()>0){
this.liked.push(post.key);
}

 });
 this.forceRerender();
    },
    forceRerender() {
      console.log("ranks render")
      this.componentKey += 1;
    },
    sortArrays(arrays) {
            return _.orderBy(arrays, 'saved', 'desc');
        },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    handleLike(index) {

if(this.liked.includes(index)){

let index1 = this.liked.indexOf(index);
// this.liked.splice(index1, 1);
console.log("inde x:"+index1)
this.$delete(this.liked,index1)

firebase.database().ref('users').child(index).update({
  like:firebase.database.ServerValue.increment(-1)

})
firebase.database().ref('users').child(this.uid).child("liked").child(index).remove();

}else{


this.liked.push(index);
firebase.database().ref('users').child(index).update({
  like:firebase.database.ServerValue.increment(1)

})

firebase.database().ref('users').child(this.uid).child("liked").child(index).set({
  like:index
})
}

this.liked.forEach(likes=>{

  console.log(likes);
})

    },
  }
};
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

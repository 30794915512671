<template>
  <div >
    <!-- <h2 class="text-center">Charts.js</h2>
    <p class="category text-center">
      Simple yet flexible JavaScript charting for designers &amp; developers.
      Made by our friends from
      <a target="_blank" rel="noopener" href="https://vue-chartjs.org/"
        >Vue Charts.js</a
      >. Please check
      <a
        target="_blank"
        rel="noopener"
        href="https://vue-chartjs.org/guide/#vue-single-file-components"
        >the full documentation</a
      >.
    </p> -->
    <div class="row">

      <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.title">
      <stats-card
        :title="card.title"
        :sub-title="card.subTitle"
        :type="card.type"
        :icon="card.icon"
      >
        <div slot="footer" v-html="card.footer"></div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6" v-for="device in devices" :key="device.num">
      <stats-card
       
        sub-title="Watts"
        type="info"
        icon="tim-icons icon-triangle-right-17"
      >
       <base-button  @click="info(device.num,device.ip)" type="danger" round icon>
                <i class="tim-icons icon-button-power"></i>
              </base-button>
        <div slot="footer">
          <i class="tim-icons icon-link-72"></i>{{device.name}}
        </div>
      </stats-card>
    </div>

    

    <!-- <div class="col-lg-3 col-md-6">
      <stats-card
        :title="phase2+phase1"
        sub-title="Watts"
        type="info"
        icon="tim-icons icon-chart-bar-32"
      >
        <div slot="footer">
          <i class="tim-icons icon-sound-wave"></i>Phase 1
        </div>
      </stats-card>
    </div> -->
    

      <div class="col-md-12 ml-auto">
        <card class="card-chart" no-footer-line>
          <template slot="header">
            
            <h5 class="card-category">Weekly Energy consumption</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-chart-bar-32 text-primary "></i>Total {{grandtotal}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-primary "></i>Total ${{(grandtotal*0.17/1000).toFixed(2)}}
            </h3>
          </template>
          <div class="chart-area">

  <v-row  v-if="componentKey0===0" align="center"
      justify="center">
      <v-col>
      <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
      </v-col>
  </v-row>


            <line-chart
         
              :chart-data="lineChart1.chartData"
              :gradient-colors="lineChart1.gradientColors"
              :gradient-stops="lineChart1.gradientStops"
              :extra-options="lineChart1.extraOptions"
              :height="200"   
              :key="componentKey0"     
            >

            
            </line-chart>
          </div>
        </card>
      </div>

     
    </div>

    <div class="row">
      <div class="col-md-8 ml-auto mr-auto">
        <!-- <card class="card-subcategories card-plain"> -->
          <tabs
            type="primary"
            
            square
            centered
            class="row"
          >
            <tab-pane>
              <span slot="label"
              v-on:click="change(0)">
                <i class="tim-icons icon-watch-time"></i>24H
              </span>
  
      
            </tab-pane>

            <tab-pane>
              <span slot="label"
              v-on:click="change(1)">
                <i class="tim-icons icon-single-copy-04"></i>30D
              </span>
              
            </tab-pane>

            <tab-pane>
              <span slot="label"
              v-on:click="change(2)">
                <i class="tim-icons icon-zoom-split"></i>Custom
              </span>

              <div class="row">

                 

                  <div class="col-md-6 ml-auto mr-auto">
 <base-input>
  
  <el-date-picker v-model="from"
                    type="datetime"
                    placeholder="Select date and time from">
    </el-date-picker>
</base-input> 

</div>
<div class="col-md-6 ml-auto mr-auto">

 <base-input>
  <el-date-picker v-model="to"
                    type="datetime"
                    placeholder="Select date and time to">
    </el-date-picker>
</base-input>


              </div>
<div class="col-6">
              <base-button class="animation-on-hover" 
          type="success"
                    
                  @click.native="load()"   
        >Load</base-button>
        </div>
              </div>
            
            </tab-pane>
          </tabs>
        <!-- </card> -->
      </div>
    </div>
    <p></p>
    <br>

      <!-- <div class="row" v-if="selected ===2">
        <div class="col-md-8 ml-auto mr-auto">
  <base-input>
  
  <el-date-picker v-model="from"
                    type="datetime"
                    placeholder="Select date and time from">
    </el-date-picker>
</base-input> 
 <base-input>
  <el-date-picker v-model="to"
                    type="datetime"
                    placeholder="Select date and time to">
    </el-date-picker>
</base-input>
</div>
        </div> -->

    <div class="row">
      <div class="col-md-12 ml-auto" v-if="selected ===0">
        <card class="card-chart">
          <template slot="header">
            <div class="row">
 <div class="col-6">
            <h5 class="card-category">Energy consupmtion in last 24 hours</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-watch-time text-danger"></i> {{total24}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-info"></i>${{(total24*.17/1000).toFixed(2)}} 
            </h3>
</div>
 <div class="col-6" v-if="user.solar>0">
            <h5 class="card-category">Energy production in last 24 hours</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-watch-time text-danger"></i> {{total24solar}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-info"></i>${{(total24solar*.17/1000).toFixed(2)}} 
            </h3>
            </div>          
</div>
          </template>
          <div class="chart-area">
            <v-row  v-if="componentKey<5" align="center"
      justify="center">
      <v-col>
      <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
      </v-col>
  </v-row>
            <line-chart
              :labels="lineChart2.labels"
              :chart-data="lineChart2.chartData"
              :extra-options="lineChart2.extraOptions"
              :height="200"
               :key="componentKey"
            >
            </line-chart>
            <!-- <bar-chart
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
              :height="200"
            > 
            </bar-chart>-->
          </div>
        </card>
      </div>
       <div class="col-md-12 ml-auto" v-if="selected ===1">
        <card class="card-chart">
          <template slot="header">

            <div class="row">
 <div class="col-6">
            <h5 class="card-category">Energy consupmtion in last 30 days</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-single-copy-04 text-danger "></i> {{total30.toFixed(2)}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-info "></i>${{(total30*.17/1000).toFixed(2)}} 
            </h3>
             </div>
<div class="col-6" v-if="user.solar>0">
            <h5 class="card-category">Energy production in last 30 days</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-watch-time text-danger"></i> {{total30solar}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-info"></i>${{(total30solar*.17/1000).toFixed(2)}} 
            </h3>
            </div> 

             </div>
          </template>
          <div class="chart-area">
            <v-row  v-if="componentKey2<7" align="center"
      justify="center">
      <v-col>
      <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
      </v-col>
  </v-row>
            <line-chart
              :labels="lineChart3.labels"
              :chart-data="lineChart3.chartData"
              :extra-options="lineChart3.extraOptions"
              :height="200"
               :key="componentKey2"
            >
            </line-chart>
            <!-- <bar-chart
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
              :height="200"
            > 
            </bar-chart>-->
          </div>
        </card>
      </div>

       <div class="col-md-12 ml-auto" v-if="selected ===2">
        <card class="card-chart">
          <template slot="header">
            


             <div class="row">
 <div class="col-6">
          <h5 class="card-category">Energy consupmtion custom</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-watch-time text-danger "></i> {{totalcustom}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-info "></i>${{(totalcustom*.17/1000).toFixed(2)}} 
            </h3>
             </div>
<div class="col-6" v-if="user.solar>0">
            <h5 class="card-category">Energy production custom</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-watch-time text-danger"></i> {{totalcustomsolar}} Wh
            </h3>
            <h3 class="card-title">
              <i class="tim-icons icon-money-coins text-info"></i>${{(totalcustomsolar*.17/1000).toFixed(2)}} 
            </h3>
            </div> 

             </div>

          </template>
          <div class="chart-area">
            <v-row  v-if="customload" align="center"
      justify="center">
      <v-col>
      <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
      </v-col>
  </v-row>
            <line-chart
              :labels="lineChart4.labels"
              :chart-data="lineChart4.chartData"
              :extra-options="lineChart4.extraOptions"
              :height="200"
               :key="componentKey3"
            >
            </line-chart>
            <!-- <bar-chart
              :chart-data="barChart1.chartData"
              :extra-options="barChart1.extraOptions"
              :gradient-colors="barChart1.gradientColors"
              :gradient-stops="barChart1.gradientStops"
              :height="200"
            > 
            </bar-chart>-->
          </div>
        </card>
      </div>

      <!-- <div class="col-md-5 mr-auto">
        <card class="card-chart" no-footer-line>
          <template slot="header">
            <h5 class="card-category">Multiple Bars Chart With Grid</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-time-alarm text-warning "></i> 130,000$
            </h3>
          </template>
          <div class="chart-area">
            <bar-chart
              :chart-data="barChart2.chartData"
              :extra-options="barChart2.extraOptions"
              :height="200">
            </bar-chart>
          </div>
        </card>
      </div> -->
    </div>

    <!-- <div class="row">
      <div class="col-md-5 ml-auto">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">Simple Pie Chart</h5>

          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart1.chartData"
                  :extra-options="pieChart1.extraOptions"
                  :height="120">
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons  icon-trophy text-success "></i> 10.000$
              </h4>
              <p class="category">A total of $54000</p>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md-5 mr-auto">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">Multiple Pie Chart</h5>

          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart2.chartData"
                  :extra-options="pieChart2.extraOptions"
                  :height="120"
                >
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons  icon-tag text-warning "></i> 130,000
              </h4>
              <p class="category">Feedback from 20.000 users</p>
            </div>
          </div>
        </card>
      </div>
    </div> -->
  </div>
</template>
<script>
import LineChart from 'src/components/Charts/LineChart';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
// import BarChart from 'src/components/Charts/BarChart';
// import PieChart from 'src/components/Charts/PieChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';
import { TabPane, Tabs } from 'src/components';
import {DatePicker, TimeSelect} from 'element-ui'
import moment from 'moment';
// import mqtt from 'mqtt';
// import * as mqtt from "mqtt" 
import firebase from '../Firebase';
import StatsCard from 'src/components/Cards/StatsCard';
//import { buildSegCompareObj } from '@fullcalendar/core';
//const axios = require('axios');

export default {
  components: {
    LineChart,
    // BarChart,
    // PieChart,
    PulseLoader,
    TabPane,
    Tabs,
    StatsCard,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect
  },
  data() {
    return {
      client:"",
      devices:[],
      user:{solar:0},
      lasttime:0,
      timestamp:0,
      handle:{},
      phase1:'',
      pahse2:'',
      day:'',
     energyf:'',
     energy:'',
      from: '',
      to: '',
      days:[],
       days30:[],
      hours:[],
      hour24:[],
      total24:'',
      total24solar:'',
      total30:0,
      total30solar:'',
      custom:[],
      custom8:[],
      totalcustom:'',
      totalcustomsolar:'',
      meter:"",
      grandtotal:0,
      loaded:false,

       datatest:[0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0],
       customhours:[0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0],
      selected:0,
      componentKey: 0,
      componentKey2: 0,
      componentKey3: 0,
      componentKey0: 0,
      customload:false,
      lineChart1: {
        chartData: {
          labels: ["Sun","Mon", "Tue", "Wed", "Thr", "Fri", "Sat"],
          datasets: [
            {
              label: 'Phase 1',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0,0,0 ,0,0,0, 0,0,0, 0,0,0,]
            },
            {
              label: 'Phase 2',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0,0,0 ,0,0,0, 0,0,0, 0,0,0,]
            },
             {
              label: 'Total',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0,0,0 ,0,0,0, 0,0,0, 0,0,0,]
            }
            
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChart2: {
        chartData: {
          labels: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
          datasets: [
            {
              label: 'Wh',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0]
            }
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },
      lineChart3: {
        chartData: {
          labels: ['1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12'],
          datasets: [
            {
              label: 'Wh',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#fff019',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0,0]
            },
             
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },
      lineChart4: {
        chartData: {
          labels: ['1','2','3','4','5','6','7','8','9','10','11','12','1','2','3','4','5','6','7','8','9','10','11','12'],
          datasets: [
            {
              label: 'Wh',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0,0]
            }
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },
      statsCards: [
        {
          title: '0.00',
          subTitle: 'Cost per hour $',
          type: 'warning',
          icon: 'tim-icons icon-bank',
          footer: '<i class="tim-icons icon-money-coins"></i> fixed rate',
          route:''
        },
        {
          title: '0.00',
          subTitle: 'Volts',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i>total',
          route:''
        },
        {
          title: '0.00',
          subTitle: 'Watts',
          type: 'info',
          icon: 'tim-icons icon-chart-bar-32',
          footer: '<i class="tim-icons icon-sound-wave"></i>Phase 1',
          route:'/rank'
        },
        {
          title: '0.00',
          subTitle: 'Watts',
          type: 'success',
          icon: 'tim-icons icon-chart-bar-32',
          footer: '<i class="tim-icons icon-sound-wave"></i>Phase 2',
          route:''
        }
      ]
      
      
      
     
    };
  },
  mounted: function () {

 //this.createPost()

//   this.client = mqtt.connect('mqtt://mqtt.martinsonmachine.com:1883');

//   this.client.on('connect', function () {
//   this.client.subscribe('shellies/shellyem-109672/#', function (err) {
//     if (!err) {

      
//     }
//   })
// });

// this.client.on('message', async function (topic, message) {

//     console.log(topic.toString()+" # "+message.toString());

// });

  this.handle = setInterval(() => {

   
  
     if(this.timestamp<moment().subtract(2,'minutes').unix()*1000){
// console.log("FALSE ")
// console.log(moment().subtract(2,'minutes').unix()*1000)

this.statsCards[1].title ="0.00";
      this.statsCards[2].title ="0.00";
      this.statsCards[3].title  ="0.00";
   
this.phase1 = 0;
this.phase2 = 0;

this.statsCards[0].title=((this.phase1+this.phase2)*0.17/1000).toFixed(2);
this.statsCards[1].footer= '<i class="tim-icons icon-sound-wave"></i></i>total '+((this.phase1+this.phase2)).toFixed(2)+" Watts";

      }else{

    this.statsCards[0].title=((this.phase1+this.phase2)*0.17/1000).toFixed(2);
this.statsCards[1].footer= '<i class="tim-icons icon-sound-wave"></i></i>total '+((this.phase1+this.phase2)).toFixed(2)+" Watts";
      }
  }, 5000)
},
destroyed: function () {

  clearInterval(this.handle);
},
 async created(){

    // this.teste()
    

 this.lasttime = moment().valueOf();

  //var user = {};
     // this.user = this.$user;

     

      if(this.$route.params.id&this.$route.params.client){
      console.log("ID:"+this.$route.params.id)
      console.log("Client:"+this.$route.params.client)
        this.meter  = this.$route.params.id;//
      }else{
       
       // redicret
      }

      // this.$route.params.id

       var resRef = firebase.firestore().collection("room");
            var resSnap = await resRef.doc(this.$route.params.client).get();

        var room =  resSnap.data();
        console.log(room);
        this.devices = room.devices;

        if (room.room!=this.$route.params.id){

           console.log("erro ID:"+room.room)
        }


this.meter = room.meter;
    


    firebase.database()
    .ref("smeter").child(this.meter).child("instant")
    //.orderByChild("timestamp").startAt(moment()
    //.subtract(2,'minutes').unix()*1000)
    //.ref('manual').child("test")
    .on("value", notes => {

     
 this.statsCards[1].title =notes.child("0_voltage").val();
      this.statsCards[2].title =notes.child("0_power").val();
      this.statsCards[3].title  =notes.child("1_power").val();
   this.timestamp = notes.child("timestamp").val()
this.phase1 = parseFloat(notes.child("0_power").val());
this.phase2 = parseFloat(notes.child("1_power").val());

      



    });

   
//console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
 this.lasttime = moment().valueOf();
////  console.log("init----");

//console.log(room.checkin.seconds); 

//Date(moment().subtract(index,'months').startOf('month').unix()*1000);

  let currentTime = new Date();

   var day = moment.unix(room.checkin.seconds)

   this.lineChart1.chartData.labels=[];

   day.subtract(1, 'days');

for (let index = 0; index < 7; index++) {

var dayNumber = day.add(1, 'days');

var info = dayNumber.format("e");

this.lineChart1.chartData.labels.push(dayNumber.format("ddd"));

//console.log(dayNumber.format("e, ddd MMMM Do YYYY, h:mm:ss a"));



//.startOf('month').unix()*1000

if(info<=currentTime.getDay()){

 let databig = await this.getWeekDayMoment(dayNumber);

this.lineChart1.chartData.datasets[0].data[index]= databig[0];
  this.lineChart1.chartData.datasets[1].data[index]= databig[1];
  this.lineChart1.chartData.datasets[2].data[index]= databig[2];


}


}


 

//    for (let index = currentTime.getMonth(); index > -1; index--) {
      
//  let currentmonth = new Date(moment().subtract(index,'months').startOf('month').unix()*1000);
  
//   let databig = await this.getmonthhere(index);

//      if(this.user.solar>0){

//   let datasolar = await this.getSolarMonth(index,this.meter);
//   this.lineChart1.chartData.datasets[3].data[currentmonth.getMonth()]= datasolar[2];

//      }


//   //console.log("SOLAR TOTAL:"+datasolar[2]);

// //console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
//  this.lasttime = moment().valueOf();

//   this.lineChart1.chartData.datasets[0].data[currentmonth.getMonth()]= databig[0];
//   this.lineChart1.chartData.datasets[1].data[currentmonth.getMonth()]= databig[1];
//   this.lineChart1.chartData.datasets[2].data[currentmonth.getMonth()]= databig[2];
  

//    }
   //console.log("month----");
this.grandtotal =this.lineChart1.chartData.datasets[2].data.reduce((acc, item) => acc + item, 0).toFixed(2)
//this.loaded = true;

   this.forceRerender0();

this.hours =[]

for (let hour = 23; hour > -1; hour--) {

   
  
     let hour_info =  await this.hour(hour, this.meter);
    //console.log("hopur----"+hour);
    //console.log("Hr:"+hour+" ENR:"+hour_info);
    //  console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
    this.lineChart2.chartData.datasets[0].data[23-hour] = parseFloat(hour_info).toFixed(2);


       if(this.user.solar>0){

   let hoursolar = await this.hourSolar(hour, this.meter);

    this.lineChart2.chartData.datasets[1].data[23-hour] = parseFloat(hoursolar).toFixed(2);

       }

  this.hours.push(moment().utcOffset(-240).subtract(hour,'hours').format("hA"))
  this.hour24.push(moment().utcOffset(-240).subtract(hour,'hours').hours()) 

  if(hour%5===0){
this.forceRerender();

}

}
this.lineChart2.chartData.labels = this.hours;

//this.id=0;
this.total24= this.lineChart2.chartData.datasets[0].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);

if(this.user.solar>0){
this.total24solar= this.lineChart2.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
}


     // console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
 this.lasttime = moment().valueOf();
//console.log("final hour----");
this.forceRerender();



  },

  methods:{

    info(info,ip) {

    //alert(info);


  const mqtt = firebase.functions().httpsCallable('mqtt');

    mqtt({ ip: ip, ch:info })
      .then(response => {
        console.log(response);
      })

    },
    

  //   teste() {
  // // Simple POST request with a JSON body using fetch
  // const requestOptions = {
  //   method: "GET",
  //   headers: { "Content-Type": "application/json" },
    // headers: { "Access-Control-Allow-Headers": "Content-Type" },
    
  /// headers: { 'Access-Control-Allow-Headers': 'Authorization' },


//     headers: {"Access-Control-Allow-Origin": "*"},
// headers: {"Access-Control-Allow-Origin": "http://localhost:8080"},
// headers: {"Access-Control-Allow-Credentials": "true"},
// headers: {"Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"},
// headers: {"Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"},
   
    
   // body: JSON.stringify({ title: "Vue POST Request Example" })https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a
 // };//https://api.npms.io/v2/search?q=vue
  // fetch("https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a",requestOptions)
  //  .then(response => response.json())
  //   .then(data => (

  //     console.log(data)
      
  //     ));

    //   request(
    //     "https://api.enphaseenergy.com/api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a",
    //     {
    //       method: "GET",
    //       mode: "cors",
    //       cache: "default",
    //     }
    //   ).then(response => response.json())
    // .then(data => (

    //   console.log(data)
      
    //   ));


      
    
// },
async createPost() {
//http://localhost:8080  api.enphaseenergy.com /api/v2/systems?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a



  // axios
  // .get('https://us-central1-martinson-machine.cloudfunctions.net/testmail')
  // .then(res => {
  //   console.log(`statusCode: ${res.statusCode}`)
  //   console.log(res.data)
  // })
  // .catch(error => {
  //   console.error(error)
  //  })

  
//   const headers = new Headers();
//       headers.append(
//         "Access-Control-Allow-Origin",
//         "https://app.martinsonmachine.com"
//       );
//        headers.append(
//          "Access-Control-Allow-Headers",
// "Origin, Accept,  X-Requested-With,   Content-Type,   Access-Control-Request-Method,    Access-Control-Request-Headers,  Authorization" );
//       headers.append(
//         "Access-Control-Allow-Credentials",
//         "true"
//       );
      const request = new Request(
       "/enphaseenergy/?dest=111111",
       // "/firebase/?key=2f842fd4ada1e4f936c79da76231a680&user_id=4d6a49304d446b334e513d3d0a",
        {
          method: "GET",
          mode: "cors",
          // headers,
          cache: "default",
         // body: JSON.stringify(this.post)
        }
      );
      const res = await fetch(request);
     // const data = await res.json();
     console.log(res)



    },
     timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},
    async getWeekDayMoment(time){

      var alldata=[];
     //  console.log("--")
//console.log(time.format("e, ddd MMMM Do YYYY, h:mm:ss a"));
  // console.log("##")
     let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('smeter').child(this.meter).child("0_total")
 .orderByChild('timestamp').startAt(time.startOf('day').unix()*1000-300000)
    .endAt(time.endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 //.ref('manual').child("newmeter")
 .ref('smeter').child(this.meter).child("0_total")
.orderByChild('timestamp').startAt(time.startOf('day').unix()*1000-300000)
.endAt(time.endOf('day').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total").val()
      });

 let energytotal = ((vlast-vinit)).toFixed(2);
alldata.push(energytotal);
//////***** */
 let init1 = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('smeter').child(this.meter).child("1_total")
 .orderByChild('timestamp').startAt(time.startOf('day').unix()*1000-300000)
    .endAt(time.endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init1.forEach(init11 => {
vinit1 = init11.child("1_total").val()

      });
   
  let last1 = await  firebase.database()
 //.ref('manual').child("newmeter")
 .ref('smeter').child(this.meter).child("1_total")
.orderByChild('timestamp').startAt(time.startOf('day').unix()*1000-300000)
 .endAt(time.endOf('day').unix()*1000).limitToLast(1).once("value");
   last1.forEach(init11 => {
vlast1 = init11.child("1_total").val()
      });
 let energytotal1 = ((vlast1-vinit1)).toFixed(2);
alldata.push(energytotal1);
let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);


alldata.push(parseFloat(finaltotal.toFixed(2)));

 
       return alldata;
     
    },
    async getSolarMonth(index,meter){

      var alldata=[];
 //console.log(index+" meter:"+meter)
//   console.log(moment().subtract(index,'months').startOf('month'))
     let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("0_total_returned")
 .orderByChild('timestamp').startAt(moment().subtract(index,'months').startOf('month').unix()*1000-300000)
    .endAt(moment().subtract(index,'months').endOf('month').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 //.ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("0_total_returned")
.orderByChild('timestamp').startAt(moment().subtract(index,'months').startOf('month').unix()*1000-300000)
.endAt(moment().subtract(index,'months').endOf('month').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total_returned").val()
      });

 let energytotal = ((vlast-vinit)/1000).toFixed(2);
alldata.push(energytotal);
//////***** */
 let init1 = await firebase.database()
   // .ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("1_total_returned")
 .orderByChild('timestamp').startAt(moment().subtract(index,'months').startOf('month').unix()*1000-300000)
    .endAt(moment().subtract(index,'months').endOf('month').unix()*1000).limitToFirst(1).once("value");
      
      init1.forEach(init11 => {
vinit1 = init11.child("1_total_returned").val()

      });
   
  let last1 = await  firebase.database()
 //.ref('manual').child("newmeter")
 .ref('smeter').child(meter).child("1_total_returned")
.orderByChild('timestamp').startAt(moment().subtract(index,'months').startOf('month').unix()*1000-300000)
.endAt(moment().subtract(index,'months').endOf('month').unix()*1000).limitToLast(1).once("value");
   last1.forEach(init11 => {
vlast1 = init11.child("1_total_returned").val()
      });
 let energytotal1 = ((vlast1-vinit1)/1000).toFixed(2);
alldata.push(energytotal1);
let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

alldata.push(parseFloat(finaltotal.toFixed(2)));

 
       return alldata;
     
    },
   async load(){

// let timefrom = new Date(this.from);

// let timeto = new Date(this.to);
this.customload=true;

this.lineChart4.chartData.datasets[0].data = []
  if(this.user.solar>0){
this.lineChart4.chartData.datasets[1].data = []
  }

let a = moment(this.from);
let b = moment(this.to);


//console.log(b.diff(a, 'hours'))

let timeframe =b.diff(a, 'hours');

this.custom=[];
this.custom8=[];

if(timeframe>25){

let now = new Date();


let test = moment(now);

let timeframe = test.diff(a, 'days');
let diff = b.diff(a, 'days');


this.lineChart4.chartData.datasets[0].data =[];
  for (let dia1 = timeframe; dia1 > timeframe-diff; dia1--) {

this.custom.push(moment().subtract(dia1,'days').format("D-MMM"))
  this.custom8.push(moment().subtract(dia1,'days').format("D"))

let info = await this.month(dia1,this.meter);//"shellyem-D3B144"
   this.lineChart4.chartData.datasets[0].data.push(parseFloat(info));

   if(this.user.solar>0){

     let solar = await this.monthSolar(dia1, this.meter)
     
   this.lineChart4.chartData.datasets[1].data.push(solar)
     }


//    if(dia1%5===0){
// this.forceRerender2()

//    }



   }
   this.lineChart4.chartData.labels = this.custom;
this.totalcustom = this.lineChart4.chartData.datasets[0].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
if(this.user.solar>0){
this.totalcustomsolar = this.lineChart4.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
}
this.customload=false;
this.forceRerender3();
}
else{



for (let hour = timeframe-1; hour > -1; hour--) {
  
  this.custom.push(moment(this.to).subtract(hour,'hours').format("hA"))
  this.custom8.push(moment(this.to).subtract(hour,'hours').hours()) 

//  const time = timeframe-1-hour;

  let info = await this.dayhere(this.to,hour,this.meter)

this.lineChart4.chartData.datasets[0].data.push(info)
  if(this.user.solar>0){

     let solar = await this.dayheresolar(this.to,hour, this.meter)
     
   this.lineChart4.chartData.datasets[1].data.push(solar)
     }
}

this.lineChart4.chartData.labels = this.custom;

this.totalcustom = this.lineChart4.chartData.datasets[0].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);

if(this.user.solar>0){
this.totalcustomsolar = this.lineChart4.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
}
this.customload=false;
this.forceRerender3();
      
}

    },
    forceRerender0(){
      console.log("charts render 0");
      this.componentKey0 += 1;
    },
    forceRerender() {
      console.log("charts render 1");
      this.componentKey += 1;
    },
    forceRerender2() {
      console.log("charts render 2 ");
      this.componentKey2 += 1;
    },
    forceRerender3() {
      console.log("charts render 3 ");
      this.componentKey3 += 1;
    },
   async change(index){
//console.log(index);
this.selected = index;

this.forceRerender();

if(index===1&&this.total30===0){


    this.days =[]
    for (let dia1 = 30; dia1 > -1; dia1--) {

     this.days.push(moment().utcOffset(-240).subtract(dia1,'days').format("D-MMM"))
  this.days30.push(moment().utcOffset(-240).subtract(dia1,'days').format("D"))


   this.lineChart3.chartData.datasets[0].data[30-dia1]=await this.month(dia1, this.meter)
    
    if(this.user.solar>0){
     
   this.lineChart3.chartData.datasets[1].data[30-dia1]=await this.monthSolar(dia1, this.meter)
     }
//console.log(dia1)
  // console.log(parseInt(this.lasttime)-parseInt(moment().valueOf()));
 this.lasttime = moment().valueOf();

   if(dia1%5===0){
this.forceRerender2()

}

}
 this.lineChart3.chartData.labels = this.days;
this.total30 = this.lineChart3.chartData.datasets[0].data.reduce((acc, item) => acc + parseFloat(item), 0)
  
  if(this.user.solar>0){
this.total30solar= this.lineChart3.chartData.datasets[1].data.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0).toFixed(2);
}

   
}




    },

    async  monthSolar(dia, meter){


      let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
 .ref('smeter').child(meter).child("0_total_returned")
 .orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
    .endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 .ref('smeter').child(meter).child("0_total_returned")
.orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
.endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total_returned").val()
      });

 let energytotal = (vlast-vinit).toFixed(2);


 let init2 = await firebase.database()
 .ref('smeter').child(meter).child("1_total_returned")
 .orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
    .endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init2.forEach(init2 => {
vinit1 = init2.child("1_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last2 = await  firebase.database()
 .ref('smeter').child(meter).child("1_total_returned")
.orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
.endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToLast(1).once("value");
   last2.forEach(init2 => {
vlast1 = init2.child("1_total_returned").val()
      });

let energytotal1 = (vlast1-vinit1).toFixed(2);

let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

 //console.log(energytotal)

 return finaltotal.toFixed(2);


    },
  async  month(dia, meter){


      let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
 .ref('smeter').child(meter).child("0_total")
 .orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
    .endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 .ref('smeter').child(meter).child("0_total")
.orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
.endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total").val()
      });

 let energytotal = (vlast-vinit).toFixed(2);


 let init2 = await firebase.database()
 .ref('smeter').child(meter).child("1_total")
 .orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
    .endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToFirst(1).once("value");
      
      init2.forEach(init2 => {
vinit1 = init2.child("1_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last2 = await  firebase.database()
 .ref('smeter').child(meter).child("1_total")
.orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'days').startOf('day').unix()*1000-300000)
.endAt(moment().utcOffset(-240).subtract(dia,'days').endOf('day').unix()*1000).limitToLast(1).once("value");
   last2.forEach(init2 => {
vlast1 = init2.child("1_total").val()
      });

let energytotal1 = (vlast1-vinit1).toFixed(2);

let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

 //console.log(energytotal)

 return finaltotal.toFixed(2);


    },
    async  hour(dia, meter){
//console.log("Hour>timestamp:"+ moment().subtract(dia,'hours').startOf('hour').format("dddd, MMMM Do YYYY, h:mm:ss a"));
//console.log("Hour>timestamp:"+ moment().subtract(dia,'hours').endOf('hour').format("dddd, MMMM Do YYYY, h:mm:ss a"));

      let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
 .ref('smeter').child(meter).child("0_total")
 .orderByChild('timestamp').startAt(moment().subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment().subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 .ref('smeter').child(meter).child("0_total")
.orderByChild('timestamp').startAt(moment().subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment().subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total").val()
      });

 let energytotal = (vlast-vinit).toFixed(2);


 let init2 = await firebase.database()
 .ref('smeter').child(meter).child("1_total")
 .orderByChild('timestamp').startAt(moment().subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment().subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init2.forEach(init2 => {
vinit1 = init2.child("1_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last2 = await  firebase.database()
 .ref('smeter').child(meter).child("1_total")
.orderByChild('timestamp').startAt(moment().subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment().subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last2.forEach(init2 => {
vlast1 = init2.child("1_total").val()
      });

let energytotal1 = (vlast1-vinit1).toFixed(2);

let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

 //console.log(energytotal)

 return finaltotal.toFixed(2);


    },
    async  hourSolar(dia, meter){


      let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
 .ref('smeter').child(meter).child("0_total_returned")
 .orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment().utcOffset(-240).subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 .ref('smeter').child(meter).child("0_total_returned")
.orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment().utcOffset(-240).subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total_returned").val()
      });

 let energytotal = (vlast-vinit).toFixed(2);


 let init2 = await firebase.database()
 .ref('smeter').child(meter).child("1_total_returned")
 .orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment().utcOffset(-240).subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init2.forEach(init2 => {
vinit1 = init2.child("1_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last2 = await  firebase.database()
 .ref('smeter').child(meter).child("1_total_returned")
.orderByChild('timestamp').startAt(moment().utcOffset(-240).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment().utcOffset(-240).subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last2.forEach(init2 => {
vlast1 = init2.child("1_total_returned").val()
      });

let energytotal1 = (vlast1-vinit1).toFixed(2);

let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

 //console.log(energytotal)

 return finaltotal.toFixed(2);


    },
    async  dayhere(to,dia, meter){

       // console.log("timestamp:"+ moment(to).subtract(dia,'hours').startOf('hour').format("dddd, MMMM Do YYYY, h:mm:ss a"));
       // console.log("timestamp:"+ moment(to).subtract(dia,'hours').endOf('hour').format("dddd, MMMM Do YYYY, h:mm:ss a"));
      let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
 .ref('smeter').child(meter).child("0_total")
 .orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 .ref('smeter').child(meter).child("0_total")
.orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total").val()
      });

 let energytotal = (vlast-vinit).toFixed(2);


 let init2 = await firebase.database()
 .ref('smeter').child(meter).child("1_total")
 .orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init2.forEach(init2 => {
vinit1 = init2.child("1_total").val()
      });
   
    //  init[0].child("0_total").val();


 let last2 = await  firebase.database()
 .ref('smeter').child(meter).child("1_total")
.orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last2.forEach(init2 => {
vlast1 = init2.child("1_total").val()
      });

let energytotal1 = (vlast1-vinit1).toFixed(2);

let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

 console.log(dia+"#"+finaltotal)

 return finaltotal.toFixed(2);


    },
    async  dayheresolar(to,dia, meter){

       // console.log("timestamp:"+ moment(to).subtract(dia,'hours').startOf('hour').format("dddd, MMMM Do YYYY, h:mm:ss a"));
       // console.log("timestamp:"+ moment(to).subtract(dia,'hours').endOf('hour').format("dddd, MMMM Do YYYY, h:mm:ss a"));
      let vinit=0,vlast=0,vinit1=0,vlast1=0;

 let init = await firebase.database()
 .ref('smeter').child(meter).child("0_total_returned")
 .orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init.forEach(init1 => {
vinit = init1.child("0_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last = await  firebase.database()
 .ref('smeter').child(meter).child("0_total_returned")
.orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last.forEach(init1 => {
vlast = init1.child("0_total_returned").val()
      });

 let energytotal = (vlast-vinit).toFixed(2);


 let init2 = await firebase.database()
 .ref('smeter').child(meter).child("1_total_returned")
 .orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
    .endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToFirst(1).once("value");
      
      init2.forEach(init2 => {
vinit1 = init2.child("1_total_returned").val()
      });
   
    //  init[0].child("0_total").val();


 let last2 = await  firebase.database()
 .ref('smeter').child(meter).child("1_total_returned")
.orderByChild('timestamp').startAt(moment(to).subtract(dia,'hours').startOf('hour').unix()*1000-300000)
.endAt(moment(to).subtract(dia,'hours').endOf('hour').unix()*1000).limitToLast(1).once("value");
   last2.forEach(init2 => {
vlast1 = init2.child("1_total_returned").val()
      });

let energytotal1 = (vlast1-vinit1).toFixed(2);

let finaltotal =  parseFloat(energytotal)+parseFloat(energytotal1);

 console.log(dia+"#"+finaltotal)

 return finaltotal.toFixed(2);


    }
  }
};
</script>
<style></style>

<template>
    <div>
      
      <h4 class="title pl-3">
        Meter Grid <small>Showing all smart devices that you control</small>
      </h4>

   <div class="col-md-6 ml-auto mr-auto">
         <stats-card :title="'$'+(total*price/1000).toFixed(2)+' per hour'" :sub-title="(total).toFixed(2)+' Watts'" type="warning" icon="tim-icons icon-bank">
          <div slot="footer" v-html="meters.length+' meters connected'"></div>
        </stats-card>
</div>

      <div class="row">
        <div class="col-sm-6 col-lg-3" v-for="meter in meters" :key="meter.devices.id">
          <meter-card :title="meter.devices.desc" 
          sub-title="Watts"
           :type="warning"
            icon="tim-icons icon-chart-bar-32" 
            :footer="meter.devices.desc"
            :device_code="meter.devices.device_code"
            :device="meter.devices.device"
            :num="meter.devices.num"
            :tipo="meter.devices.type"
            :id="meter.devices.id"
             :route="'/meters/'+meter.devices.id"
              @cli="onClickChild"> </meter-card>
          <!-- <div class="card ">
            <div class="card-body text-center py-5">
              <code>col-sm-6 col-lg-3</code>
            </div>
          </div> -->
        </div>
      
      </div>
      
      
     
    </div>
  </template>
  <script>

import firebase from '../Firebase';
import MeterCard from 'src/components/Cards/MeterCard';

import StatsCard from 'src/components/Cards/StatsCard';
import supabase from '@/supabase.js'

  export default {
    name: 'List',
    components: {
      MeterCard,

    StatsCard,
    },
    data(){
      return{
        meters:[],
        info:[],
        total:0,
        price:.17,
        user:{},
        handle:{}

      }
    },
    mounted: function () {

//this.createPost()

this.handle = setInterval(() => {



  //if (this.timestamp < moment().subtract(2, 'minutes').unix() * 1000) {
    if (true) {
   
    //  const result = data.find(({ time }) => time === parseFloat(moment().subtract(dia1, 'days').format("D")));


      this.total = this.info.reduce((acc, item) => acc + parseFloat(item), 0)

      this.info=[];

  } else {

    this.statsCards[0].title = ((this.phase1 + this.phase2) * 0.17 / 1000).toFixed(2);
    this.statsCards[1].footer = '<i class="tim-icons icon-sound-wave"></i></i>total ' + ((this.phase1 + this.phase2)).toFixed(2) + " Watts";
  }
}, 5000)

},

destroyed: function () {

clearInterval(this.handle);
},

async created() {

  this.user = this.$user;


  const { data, error } = await supabase
  .from('user_devices')
  .select(' devices(id,device_code,desc,num,device,type)')
  .eq('customer_id', this.user.id)

  // const { data, error } = await supabase
  // .from('Devices')
  // .select('id,device_code,desc,num,device,type, customers(id,name)')
  // .eq('user_id', this.user.id)

  console.log(" user id",this.user.id);

  console.log(" list meters",data);

  this.meters = data;

},

    methods: {
      onClickChild (value) {
      //    console.log(value)
          
          this.info.push(value.total)// someValue


      }
    }

  };
  </script>
  <style></style>
  
<template>
  <div class="row">
    <!-- Big Chart -->
    <div class="col-12">
  
      <card type="chart">
           
        <template slot="header">
      
          <div class="row">
           
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Total</h5>
              <h2 class="card-title">Energy Comsumption</h2>
            </div>
            <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                :class="isRTL ? 'float-left' : 'float-right'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in bigLineChartCategories"
                  :key="option.name"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: bigLineChart.activeIndex === index }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initBigChart(index)"
                    name="options"
                    autocomplete="off"
                    :checked="bigLineChart.activeIndex === index"
                  />
                  <span class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </template>
        <div class="chart-area">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
            :key="componentKey"
          >
          </line-chart>
        </div>
      </card>
    </div>
    <!-- Stats Cards -->
    <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.title">
      <stats-card
        :title="card.title"
        :sub-title="card.subTitle"
        :type="card.type"
        :icon="card.icon"
        :route="card.route"
      >
        <div slot="footer" v-html="card.footer"></div>
        
      </stats-card>
    </div>

    <!-- Small charts -->
    <!-- <div class="col-lg-4" :class="{ 'text-right': isRTL }">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">Total Shipments</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-bell-55 text-primary "></i> 763,215
          </h3>
        </template>
        <div class="chart-area">
          <line-chart
            style="height: 100%"
            :chart-data="purpleLineChart.chartData"
            :gradient-colors="purpleLineChart.gradientColors"
            :gradient-stops="purpleLineChart.gradientStops"
            :extra-options="purpleLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div> -->
    <!-- <div class="col-lg-4" :class="{ 'text-right': isRTL }">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">Daily Sales</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-delivery-fast text-info "></i> 
            3,500€
          </h3>
        </template>
        <div class="chart-area">
          <bar-chart
            style="height: 100%"
            :chart-data="blueBarChart.chartData"
            :gradient-stops="blueBarChart.gradientStops"
            :extra-options="blueBarChart.extraOptions"
          >
          </bar-chart>
        </div>
      </card>
    </div> -->
    <!-- <div class="col-lg-4" :class="{ 'text-right': isRTL }">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">Completed tasks</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-send text-success "></i> 12,100K
          </h3>
        </template>
        <div class="chart-area">
          <line-chart
            style="height: 100%"
            :chart-data="greenLineChart.chartData"
            :gradient-stops="greenLineChart.gradientStops"
            :extra-options="greenLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div> ////////#######???????? -->
    <!-- <div class="col-lg-5">    
      <card type="tasks" :header-classes="{ 'text-right': isRTL }">
        <template slot="header">
          <h6 class="title d-inline">latest posts</h6>
         
        </template>
        <div class="table-full-width table-responsive">
          <task-list></task-list>
        </div>
      </card>
    </div> -->

       <!-- <div class="col-lg-7">
      <card class="card" :header-classes="{ 'text-right': isRTL }">
        <h5 slot="header" class="card-title">Top 6 energy savers<router-link to="/rank"><h5 class="col-md-3 ml-auto
">see all</h5></router-link></h5>


        
        <div class="table-responsive">
          <user-table></user-table>
          </div>
      </card>
    </div> -->

 
     


      

      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-success card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h4 class="card-title">#1</h4>
          <img class="card-img" src="img/card-success.png" alt="Image" />
          <ul class="list-group">
          <li class="list-group-item">Lighting is responsible for 22% of your energy bill.</li>
            <li class="list-group-item">Why should I change to LED?</li>
          </ul>
          <div class="card-prices">
            <!-- <h3 class="text-on-front"><span>$</span>57</h3>
            <h5 class="text-on-back">57</h5>
            <p class="plan">Basic plan</p> -->
          </div>

          <base-button slot="footer" round type="success" class="btn-just-icon"
          @click="ModalVisible1 = true">
            Learn more
          </base-button>
        </card>
      </div>

      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-success card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h4 class="card-title" ><span>#2</span ></h4>
          <img class="card-img" src="img/card-warning.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">Phantom Power is 19% of your energy bill.</li>
            <li class="list-group-item">Unplug electronics that is not been used</li>
             <!--<li class="list-group-item">24/7 Support</li> -->
          </ul>
          <div class="card-prices">
            <!-- <h3 class="text-on-front"><span>1</span>1</h3> -->
           <!--  <h5 class="text-on-back">72</h5> -->
            <!-- <p class="plan">dsdsd</p> -->
          </div>

          <base-button slot="footer" round type="warning" class="btn-just-icon"
          @click="ModalVisible1 = true">
            Learn more
          </base-button>
        </card>
      </div>
      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-danger card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h4 class="card-title">#3</h4>
          <img class="card-img" src="img/card-danger.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">Almost half of your bill is related to HVAC system.</li>
            <li class="list-group-item">Change filters regularly to improve the efficiency.</li>
            <!-- <li class="list-group-item">No Support</li> -->
          </ul>
          <div class="card-prices">
            <!-- <h3 class="text-on-front"><span>$</span>9</h3>
            <h5 class="text-on-back">9</h5>
            <p class="plan">Trial plan</p> -->
          </div>

          <base-button slot="footer" round type="danger" class="btn-just-icon"
          @click="ModalVisible1 = true"
          >
          
            Learn more
          </base-button>
        </card>
      </div>

       <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-primary"
          footer-classes="text-center mb-3 mt-3"
        >
          <h4 class="card-title">#4</h4>
          <img class="card-img" src="img/card-primary.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">Insulation prevent air to going in and out.</li>
            <li class="list-group-item">An airtight building is a key goal to pursuit. </li>
          </ul>
          <div class="card-prices">
           
          </div>
          <base-button
          @click="ModalVisible1 = true"
           slot="footer" 
           round type="primary" class="btn-just-icon">
           Learn more
          </base-button>

          
        </card>
      </div>

       <modal
        :show.sync="ModalVisible1"
        class="modal-search"
        id="searchModal1"
        :centered="false"
        :show-close="true"
      >
       

        <!-- <div><section>
          <iframe width="600" height="400" src="https://www.youtube.com/embed/IQw-4JABPCM?controls=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </section></div> -->
         
        <!-- <base-button class="animation-on-hover" 
          type="success"
            @click.native="checkperson()"    
        >Post</base-button> -->
       Coming soon!
      </modal>

   
     <div class="col-md-12 mt-5"><h3 class="title">
       <base-button
          @click="ModalVisible1 = true"
           >
           Learn more
          </base-button>
       </h3></div>
     
    <!-- <div class="col-lg-12"><country-map-card></country-map-card></div> -->
  </div>
  
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
//import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import TaskList from './TaskList';
import UserTable from './UserTable';
//import CountryMapCard from './CountryMapCard';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import firebase from '../../Firebase';
import moment from 'moment';
//import swal from 'sweetalert2';
import { Modal } from '@/components';
import axios from 'axios';

import supabase from '@/supabase.js'




var bigChartData = [
  [0,0,0,0,0,0,0,0,0,0,0,0],
  ]
let bigChartLabels = [['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],["SUN","MON", "TUE", "WED", "THR", "FRI", "SAT"]]
let bigChartDatasetOptions = {
  label: 'Wh',
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
  yAxisID: 'A',
}
let bigChartDatasetOptionsTemp = {
  label: '°F',
  fill: true,
  borderColor: config.colors.danger,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.danger,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.danger,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
  yAxisID: 'B',
}

export default {
  components: {
    LineChart,
   // BarChart,
    StatsCard,
    TaskList,
    // CountryMapCard,
    UserTable,
     Modal,
  },
  data() {
    return {
     ModalVisible1:false,
       activeNotifications: false,
       saved:"",
      info:"",
      meter:"",
    componentKey: 0,
      bigChartData1:[[]],
      id:0,
      monthint:0,
     energyf:{},
     energy:{},
     datalabels:["KWh","Wh"],
     datatest:[0.0,0.0,0.0,0.0,0.0,0.0,0.0],
      statsCards: [
        {
          title: '0.00',
          subTitle: 'Cost $',
          type: 'warning',
          icon: 'tim-icons icon-bank',
          footer: '<i class="tim-icons icon-money-coins"></i> fixed rate',
          route:''
        },
        {
          title: '0.00',
          subTitle: 'Energy KWh',
          type: 'primary',
          icon: 'tim-icons icon-bulb-63',
          footer: '<i class="tim-icons icon-sound-wave"></i></i> current month',
          route:''
        },
        {
          title: '0.00',
          subTitle: 'Savings $',
          type: 'info',
          icon: 'tim-icons icon-coins',
          footer: '<i class="tim-icons icon-trophy"></i> ',//check the rank
          route:''///rank
        },
        {
          title: '1',
          subTitle: 'Meters',
          type: 'danger',
          icon: 'tim-icons icon-molecule-40',
          footer: '<i class="tim-icons icon-molecule-40"></i>Check your meters',
          route:'/listmeters'
        }
      ],
      bigLineChart: {
        activeIndex: 10,
        chartData: {
          datasets: [{
          ...bigChartDatasetOptions,
            data: bigChartData[0],
          }
        ],
          labels: bigChartLabels[0]
        },
        
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      purpleLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0]
      },
      greenLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV'],
          datasets: [
            {
              label: 'My First dataset',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [90, 27, 60, 12, 80]
            }
          ]
        },
        gradientColors: [
          'rgba(66,134,121,0.15)',
          'rgba(66,134,121,0.0)',
          'rgba(66,134,121,0)'
        ],
        gradientStops: [1, 0.4, 0]
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
          datasets: [
            {
              label: 'Countries',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    };
    
  },
  // mounted(){

  //            if(!this.$user.addedcard){

  //                this.$router.push({ path: '/forms/wizard' }).catch(error => {
  //               console.info(error.message)
  //            })
  //            }

  // },
  async created() {


    


 this.bigChartData1[0] = [0,0,0,0,0,0,0,0,0,0,0,0];
//console.log( (await this.Temp()));

             var user = {};

             var { data, error } = await supabase
             .from('user_devices')
  .select('customers(*),devices(id,device_code,desc,num,device,type)')
  .eq('customer_id', this.$user.id);

  console.log(data);

  

            user = data[0].customers;


  //            if(!user.addedcard){

  //                this.$router.push({ path: '/forms/wizard' }).catch(error => {
  //               console.info(error.message)
  //            })    
  //  }
          
//           await currentUser.updateProfile({
//         displayName: `${user.firstName} ${user.lastName}`,
// })
// console.log(user.firstname)



    var currentTime = new Date();
    var dayweek = currentTime.getDay();
  
this.statsCards[2].title = this.formatPrice(user.savings);

if(data.length>0){
  this.statsCards[3].title = data.length;

  var { data, error } = await supabase.rpc('dash_year', { uid:this.$user.id,year:moment().local().format("YYYY"),zone:""  })


console.log("#####");
if (error) console.error(error)
else console.log('dash_year',data)

  for (let index = currentTime.getMonth(); index > -1; index--) {
      

   // console.log('mes:',index);
 //let currentmonth = new Date(moment().subtract(index,'months').startOf('month').unix()*1000);

 const result = data.find(({ time }) => time === index+1);

 var energyvar=0;

  if(result){
    // console.log('result:',result);
    // console.log('month:',currentmonth.getMonth());
    energyvar= result.total;
    this.bigChartData1[0][index]= energyvar.toFixed(2)
  }else{
    this.bigChartData1[0][index]= 0;

  }
  
 

  if(index===currentTime.getMonth()){
     energyvar = parseFloat(energyvar/1000);
  this.statsCards[0].title = (energyvar*.17).toFixed(2);
    this.statsCards[1].title = energyvar.toFixed(2);
  this.forceRerender();
}

    }
    let current = moment().subtract(dayweek,'days').startOf('day');

    //console.log('day',current.format());

    var { data, error } = await supabase.rpc('dash_week', { uid:this.$user.id,datain:current.format(),zone:""  })


console.log("#####");
if (error) console.error(error)
//else console.log('dash_week',data)


    for (let day = dayweek;  day > -1; day--) {
      // let tets = await this.weekday(day,this.meter);
      var energyvar=0;
      const result = data.find(({ time }) => time === day);

      if(result){
    // console.log('result:',result);
    // console.log('month:',currentmonth.getMonth());
    energyvar= result.total;
    this.datatest[day] = energyvar.toFixed(2)
    
  }else{
    this.datatest[day] = (0).toFixed(2)

  }

     //  console.log("day: "+day+" week: "+(dayweek+1-day)+" tets:"+tets)

      
    }
   this.bigChartData1.push(this.datatest);


}else{

  this.statsCards[3].title = "0";
  this.statsCards[3].footer = '<i class="tim-icons icon-molecule-40"></i>Add your meters';
 // this.statsCards[3].route = '/listmeters';

  this.statsCards[3].route = '/table-list/regular';//

  
}
  




   },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [{ name: 'Monthly', icon: 'tim-icons icon-minimal-left' }, { name: 'Weekly', icon: 'tim-icons icon-minimal-right' }];
    }
  },

  
  methods: {
    async Temp() {
      var start_date_year = (moment.unix(moment().unix()).format("YYYY")) + "-01-01";
      var first_week = moment((moment.unix(moment().unix()).format("YYYY")) + "-01-07").unix();
      var start_date_1_week = moment.unix(moment().subtract(144, 'hours').startOf('hour').unix()).format("YYYY-MM-DD");
      var start_week = moment().day(0).format("YYYY-MM-DD");
      var end_date_time = moment().unix();
      var end_date_year = moment.unix(moment().unix()).format("YYYY-MM-DD");
      var end_date = moment.unix(end_date_time).format("YYYY-MM-DD");
      var bigChartDataTemp = new Array(2);
      bigChartDataTemp[0] = await this.Temperature_Yearly(start_date_1_week, end_date_year, start_date_year, first_week);
      bigChartDataTemp[1] = await this.Temperature_week(start_week, end_date);
      return bigChartDataTemp;
    },

    async Temperature_Yearly(start_date_1_week, end_date_year, start_date_year, first_week) {
      const parameter = "&daily=temperature_2m_max,temperature_2m_min";
      const timezone = "&timezone=America%2FNew_York";
      var temp_max = [];
      var temp_min = [];
      var temp_average = [];
      var temp_average_year = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var average = 0;
      var time = [];
      var month = 1;
      var count = 0;
      const resp = await this.Request_hist(parameter, start_date_year, end_date_year, timezone);
      const resp_7_days = await this.Req(parameter, start_date_1_week, end_date_year, timezone);
      if (moment(start_date_1_week).unix() <= first_week) {
        temp_max = resp_7_days['daily']['temperature_2m_max'];
        temp_min = resp_7_days['daily']['temperature_2m_min'];
        time = resp['daily']['time'];
        for (var i = 0; i < temp_max.length; i++) {
          temp_average.push(parseFloat((temp_max[i] + temp_min[i]) / 2).toFixed(2));
        }
        temp_average_year[0] = (parseFloat(temp_average / temp_average.length).toFixed(2))
        console.log(temp_average_year);
        return temp_average;
      }
      else {
        temp_max = ((resp['daily']['temperature_2m_max']).filter(function (e) { return e })).concat(resp_7_days['daily']['temperature_2m_max']);
        temp_min = ((resp['daily']['temperature_2m_min']).filter(function (e) { return e })).concat(resp_7_days['daily']['temperature_2m_min']);
        time = resp['daily']['time'];
        for (var a = 0; a < time.length; a++) {
          time[a] = (moment.unix(time[a]).format("M"));
          temp_average.push((temp_max[a] + temp_min[a]) / 2);
        }
        for (var j = 0; j <= (time.length); j++) {
          if (time[j] == month && j < (time.length)) {
            average = average + temp_average[j]
            count++;
          }
          else if (j == ((time.length))) {
            temp_average_year[month - 1] = (parseFloat(average / count).toFixed(2));
          }
          else {
            temp_average_year[month - 1] = (parseFloat(average / count).toFixed(2));
            average = 0;
            count = 0;
            month++;
          }
        }
      }
      //console.log(temp_average_year);
      return temp_average_year;

    },
    async Temperature_week(start_week, end_date_time) {
      const parameter = "&daily=temperature_2m_max,temperature_2m_min";
      const timezone = "&timezone=America%2FNew_York";
      var temp_max = [];
      var temp_min = [];
      var temp_average_week = [0,0,0,0,0,0,0];
      const resp = await this.Req(parameter, start_week, end_date_time, timezone);
      temp_max = resp['daily']['temperature_2m_max'];
      temp_min = resp['daily']['temperature_2m_min'];
      for (var i = 0; i < temp_max.length; i++) {
        temp_average_week[i]=(parseFloat((temp_max[i] + temp_min[i]) / 2).toFixed(2));
      }
      return (temp_average_week);
    },
    async Req(parameter, start_date, end_date, timezone) {
      const longitude = parseFloat(this.$user.lng).toFixed(2);
      const latitude = parseFloat(this.$user.lat).toFixed(2);
      var url = 'https://api.open-meteo.com/v1/forecast?latitude='+latitude+'&longitude='+longitude+parameter+'&temperature_unit=fahrenheit&timeformat=unixtime'+timezone +'&start_date='+start_date+'&end_date='+end_date;
      //console.log(url);
      try {
        const response = await axios.get(url);
        const data = response.data
        //console.log(response.data);
        return data;
      } catch (e) {
        console.log(e.response.data.error)
      }

    },

    async Request_hist(parameter, start_date, end_date, timezone) {
      const longitude = parseFloat(this.$user.lng).toFixed(2);
      const latitude = parseFloat(this.$user.lat).toFixed(2);
      var url = 'https://archive-api.open-meteo.com/v1/archive?latitude=' + latitude + '&longitude=' + longitude + '&start_date=' + start_date + '&end_date=' + end_date + parameter + timezone + '&temperature_unit=fahrenheit&timeformat=unixtime';
      try {
        const response = await axios.get(url);
        const data = response.data
        return data;
      } catch (e) {
        console.log(e.response.data.error)
      }
    },
   
    

  checkperson(){
// console.log(" test:"+Object.keys(this.persons).length);
//     this.persons.forEach(user =>{
//       console.log("Pesrons test:"+ user.email) ;
//     })


  },
  

     VaySwal(type) {
        console.log(type);
     },
    //  showSwal(type) {
    //     swal.fire({
    //       title: 'Input something',
    //       html: `<div class="form-group">
    //         <input v-model="info" id="info" type="text" class="form-control" />
    //         </div>
    //          <b-button @click.native="VaySwal('measure')" variant="primary">Save</b-button>
    //         `,
    //       showCancelButton: true,
    //       customClass: {
    //         confirmButton: 'btn btn-success btn-fill',
    //         cancelButton: 'btn btn-danger btn-fill'
    //       },
    //       buttonsStyling: false
    //     })
    //       .then(() => {
    //         swal.fire({
    //           icon: 'success',
    //           html: 'You entered '+this.info,
    //           customClass: {
    //             confirmButton: 'btn btn-success btn-fill'
    //           },
    //           buttonsStyling: false
    //         });
    //       })
    //       .catch(swal.noop);
    //  },
  
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    forceRerender() {
      console.log(" render ");
      this.componentKey += 1;
    },

   async initBigChart(index) {
   // this.bigChartDataTemp = (await (this.Temp()));
    let chartData = {
      
            datasets: [{
              ...bigChartDatasetOptions,
              
              data: this.bigChartData1[index]
            },
            // {
            //   ...bigChartDatasetOptionsTemp,
            //   data: this.bigChartDataTemp[index],
            // }
          ],
            labels: bigChartLabels[index]
          };
     
      
    this.$refs.bigChart.updateGradients(chartData);
    this.bigLineChart.chartData = chartData;
    //  this.bigLineChart.extraOptions = chartConfigs.purpleChartOptions;
    this.bigLineChart.activeIndex = index;
    this.forceRerender();
    }
    
  },
  mounted() {
//console.log("mounted")
  

  this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'en';
      this.$rtl.enableRTL();
  }
    this.initBigChart(0);
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style></style>

<template>
    <div class="container">
      <div class="row">
        <div class="col-md-5 ml-auto">
          <div class="info-area info-horizontal mt-5">
            <div class="icon icon-warning">
              <i class="tim-icons icon-wifi"></i>
            </div>
            <div class="description">
              <h3 class="info-title">Internet of things</h3>
              <p class="description">
              See how we are using active intelligence to revolutionize energy resourcefulness, create greater system awareness, and connect the Internet of Things.
              </p>
            </div>
          </div>
          <div class="info-area info-horizontal">
            <div class="icon icon-primary">
              <i class="tim-icons icon-sound-wave"></i>
            </div>
            <div class="description">
              <h3 class="info-title">Monitoring</h3>
              <p class="description">
               
             Our new technology applies to energy eliminating the lack of knowledge and generating economy and reliability.
              </p>
            </div>
          </div>
          <div class="info-area info-horizontal">
            <div class="icon icon-info">
              <i class="tim-icons icon-tap-02"></i>
            </div>
            <div class="description">
              <h3 class="info-title">Control</h3>
              <p class="description">
                Control is mandatory in order to understand, analyze and ajust our energy behavior. So we are using the new machines to reduce energy consumption and increase the energy availability.
              </p>
            </div>
          </div>
        </div>
  
        <div class="col-md-7 mr-auto">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(registerUser)">
              <card class="card-register card-white">
                <template slot="header">
                  <img class="card-img" src="img/card-danger.png" alt="Card image"/>
                  <h4 class="card-title">  Register</h4>
                </template>
            <div class="row">
              <div class="col-md-8">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    v-model="name"
                    placeholder="Name"
                    addon-left-icon="tim-icons icon-single-02"
                    type="text"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
               </ValidationProvider>
               </div>
            <div class="col-md-4">
                <ValidationProvider
                  name="Phone"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    v-model="phone"
                    placeholder="Phone"
                    addon-left-icon="tim-icons icon-single-02"
                    type="phone"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>
            </div>
            </div>
  
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    readonly
                    v-model="email"
                    placeholder="Email"
                    addon-left-icon="tim-icons icon-email-85"
                    type="email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
               </ValidationProvider>
  
                <ValidationProvider
                  name="password"
                  rules="required|min:6"
                  v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    v-model="password"
                    placeholder="Password"
                    addon-left-icon="tim-icons icon-lock-circle"
                    type="password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
               </ValidationProvider>
  
                <base-checkbox class="text-left">
                  I agree to the <a href="https://martinsonmachine.com/policy.html" target="_blank">terms and conditions</a>.
                </base-checkbox>
  
                <base-button  native-type="submit" slot="footer" type="danger" round block size="lg">
                  Get Started
                </base-button>
              </card>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </template>
  <script>
  
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import supabase from '@/supabase.js'

extend("email", email);
extend("min", min);
extend("required", required);

export default {
  data() {
    return {
      id: '',
      name: '',
      phone: '',
      email: '',
      password: '',
      token: '',
    };
  }, 
  async created() {
    this.token = (new URLSearchParams(location.search)).get('token')
    await this.verifyInvitation();
    },
  
  methods: {
    async verifyInvitation(){
    console.log('token',this.token)
      const { data, error} = await supabase
        .from('token')
        .select()
        .eq('token', this.token)
        .single()
      if (error) {
          console.log(error.message)
          this.$router.push('/error')
          return;
      }
      if(data.usertype !== 'business-manager'){
        this.$router.push('/error')
      } else{
        console.log(data)
        const expired = new Date(data.expiration)
        const now = new Date() 
        if (now <= expired) {
          this.email = data.email;
          console.log('Token válido')
        }else {
          console.error('Token expirado')
          this.$router.push('/error')
        }
      }
    },

    async registerUser() { 
      var userId="";
      
      if(this.$user.email===this.email){
        await this.refreshUserSession()
        var userType = this.$user.user_metadata.userType;
        if(userType.includes('business-manager')){
          alert('This account already has a registered Business Manager');
          return;
        }else{
          var newUserType = ((userType.toString()).split(',')).concat('business-manager');
          const { data, error } = await supabase.auth.updateUser({
            data: {
              userType: newUserType,
            },
          }) 
            console.log('data',data)
            userId=this.$user.id;
            await this.addUserToTable(userId);

            if(error){
              console.log(error.message)
              return;
            }
          }}
          else{
          const { data, error } = await supabase.auth.signUp({
            email: this.email,
            password: this.password,
            options: {
              data: {
                userType: ['business-manager'],
              }
            }
          });

          if (error) {
            console.log(error.message)
            return;
          }

          userId = data.user.id;
          console.log("Usuário registrado:",userId);
          await this.addUserToTable(userId);
        }
    },

    async addUserToTable(userId) {
      const { data, error } = await supabase
        .from("businessmanagers")
        .insert([
          { 
            id: userId,
            name: this.name,
            phone: this.phone,
            email: this.email,
          },
        ]);

        if (error) {
          if(error.code === '23503' ){
            alert('User already authenticated, make sure you are logged in before making a new registration');
            return;
          }
          alert(error.message);
          return;
        }
      console.log("Usuário adicionado à tabela Business Managers:", data);
      await this.deletetoken();
      await this.refreshUserSession();
      this.$router.push({ path: '/' });
    },

    async refreshUserSession() {
      const { error } = await supabase.auth.refreshSession()
      if (error){console.log(error.message)} 
    },

    async deletetoken(){
      console.log(this.token)
      const { data, error} = await supabase
        .from('token')
        .select()
        .eq('token', this.token)
        .single()
      if (error) {
          console.log(error.message)
          this.$router.push('/error')
          return;
      }else{ 
        const message = ' O(a) Business Manager ' + this.name + ' se cadastrou no sistema utilizando o email ' + this.email + ' às ' + new Date().toLocaleTimeString() + ' do dia ' + new Date().toLocaleDateString() +'.';
        const id_creator = data.id_creator;
        const { error2 } = await supabase
          .from('messages')
          .insert({ message, id_creator: id_creator})

        if(error2){
        console.log(error2);
        return;
        }else{
          const { error3 } = await supabase
            .from('token')
            .delete()
            .eq('token', this.token)

          if(error3){
            console.log(error3);
            return;
          }
        }
      }
    }
  },
};

  </script>
  <style></style>